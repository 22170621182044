/**
 * api接口的统一出口
 */
import authorizationRequest from "@/api/authorizationRequest";
import userRequest from "@/api/userRequest";
import organizationRequest from "@/api/organizationRequest";
import storeRequest from "@/api/storeRequest";
import vendorRequest from "@/api/vendorRequest";
import moduleRequest from "@/api/moduleRequest";
import permissionRequest from "@/api/permissionRequest";
import activityRequest from "@/api/activityRequest";
import activityStatsRequest from "@/api/activityStatsRequest";
import scheduleRequest from "@/api/scheduleRequest";
import repairRequest from "@/api/repairRequest";
import wxRequest from "@/api/wxRequest";

// 导出接口
export default {
  authorizationRequest,
  userRequest,
  organizationRequest,
  storeRequest,
  vendorRequest,
  moduleRequest,
  permissionRequest,
  activityRequest,
  activityStatsRequest,
  scheduleRequest,
  repairRequest,
  wxRequest,
};
