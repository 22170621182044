import "core-js/modules/es.array.push.js";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { Plus } from "@element-plus/icons-vue";
import util from "@/util/util";
import { useRouter, useRoute } from "vue-router";
import { ElLoading } from "element-plus";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "RepairInfo",
  components: {
    Plus,
    ElLoading
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const value = ref("");
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const textarea1 = ref("");
    const uploadTable = ref(null);
    const installationStatusData = reactive([]);
    const fullscreenLoading = ref(false);
    const uploadTableData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      uploadedList: [],
      storeInfo: {}
    });
    // 上传图片数量限制
    const maxNum = 3;

    //获取报修信息
    const selectRepairById = repairId => {
      let repairIdRO = {
        repairId: repairId
      };
      proxy.$api.repairRequest.selectRepairById(repairIdRO).then(function (response) {
        console.log(response);
        if (response.code === "200000") {
          uploadTableData.storeInfo = response.result.data;
          uploadTableData.uploadedList = response.result.data.fixPhoto;
          ElNotification({
            title: "成功",
            message: response.result.promptMsg,
            type: "success",
            duration: 1000
          });
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(function (error) {
        console.log(error);
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    //获取文件签名等信息
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          reject(error);
        });
      });
    };
    //上传文件之前
    const handleBefore = async file => {
      console.log(file);
      uploadTableData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadTableData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadTableData.signatureData.dir + fullName;
      uploadTableData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadTableData.signatureData.policy,
        OSSAccessKeyId: uploadTableData.signatureData.accessId,
        signature: uploadTableData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadTableData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    // 文件上传成功
    const handleStoreOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadTableData.data.name,
          uploadedName: uploadTableData.data.key,
          uid: file.uid
        };
        console.log(uploadedRO);
        file.uploadedRO = uploadedRO;
      }
    };
    //超出文件限制数量
    const handleExceed = () => {
      ElNotification({
        title: "失败",
        message: "最多上传" + maxNum + "张图片",
        type: "error",
        duration: 3000
      });
    };
    //删除图片之前
    const onBeforeRemove = (file, files) => {
      let uploadDeleteRO;
      let deleteIndex = null;
      for (let z in uploadTableData.uploadedList) {
        if (file.uid === uploadTableData.uploadedList[z].uid) {
          deleteIndex = z;
          break;
        }
      }
      if (file.uploadedId) {
        uploadDeleteRO = {
          uploadedId: file.uploadedId
        };
      } else {
        uploadDeleteRO = {
          uploadedName: file.uploadedRO.uploadedName
        };
      }
      console.log(uploadDeleteRO);
      // return false;
      // fullscreenLoading.value = true
      proxy.$api.repairRequest.deleteRepairPhoto(uploadDeleteRO).then(function (response) {
        if (response.code === "200000") {
          console.log(response);
          ElNotification({
            title: "成功",
            message: response.result.promptMsg,
            type: "success",
            duration: 3000
          });
          uploadTableData.uploadedList.splice(deleteIndex, 1);
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
          return false;
        }
        // fullscreenLoading.value = false
      }).catch(function (error) {
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
        fullscreenLoading.value = false;
        return false;
      });
    };
    // 删除图片
    const onRemove = (file, files) => {};
    //预览图片
    const handlePictureCardPreview = uploadFile => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    //判断是否已验收
    const getDisabled = id => {
      if (id == 4) {
        return true;
      } else {
        return false;
      }
    };
    // 点击下一步发送请求
    const submitRepairInfo = () => {
      if (uploadTableData.storeInfo.repairResultId === 4) {
        return false;
      }
      //参数
      let submitRepairRO = {
        repairId: route.query.id,
        photos: []
      };
      for (var j = 0; j < uploadTableData.uploadedList.length; j++) {
        if (uploadTableData.uploadedList[j].uploadedId) {
          console.log("没有新增图片");
        } else {
          submitRepairRO.photos.push(uploadTableData.uploadedList[j].uploadedRO);
        }
      }
      console.log(submitRepairRO);
      //如果有新增图片  发送请求。
      //如没有新增图片，直接跳转页面。
      let flag = false;
      for (let l in submitRepairRO.photos) {
        if (submitRepairRO.photos.length !== 0) {
          flag = true;
        }
      }
      console.log(flag);
      if (flag) {
        proxy.$api.repairRequest.submitRepairInfo(submitRepairRO).then(function (response) {
          if (response.code === "200000") {
            fullscreenLoading.value = false;
            router.push({
              path: "/repair-check",
              query: {
                id: route.query.id
              }
            });
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 3000
            });
            fullscreenLoading.value = false;
          }
        }).catch(function (error) {
          fullscreenLoading.value = false;
          console.log(error);
        });
      } else {
        fullscreenLoading.value = false;
        router.push({
          path: "/repair-check",
          query: {
            id: route.query.id
          }
        });
      }
    };
    onMounted(async () => {
      selectRepairById(route.query.id);
    });
    return {
      maxNum,
      value,
      dialogImageUrl,
      dialogVisible,
      textarea1,
      uploadTable,
      uploadTableData,
      installationStatusData,
      fullscreenLoading,
      onBeforeRemove,
      onRemove,
      handleStoreOnSuccess,
      handleBefore,
      handleExceed,
      handlePictureCardPreview,
      getDisabled,
      submitRepairInfo,
      router
    };
  }
};