/**
 * userRequest模块接口列表
 */

import axios from "@/util/http"; // 导入http中创建的axios实例
import qs from "qs";
import cookies from "js-cookie";
import util from "@/util/util";

let Base64 = require("js-base64").Base64;

const activityRequest = {
  getActivity(activityId, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    var param = { activityId: activityId };
    param = util.parseParams(param);
    return axios
      .get(`/api/activity/get?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  addActivity(activityAddRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/add`, activityAddRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  updateActivity(activityUpdateRO) {
    debugger;
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/update`, activityUpdateRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityBrowser(activityBrowserRO, retry) {
    let tokenJson = util.getToken();
    console.log(tokenJson);
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/activity/getActivityBrowser`, activityBrowserRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getEndActivityYear(activityBrowserRO, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .post(`/api/activity/getEndActivityYear`, activityBrowserRO, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityTag(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/activity/getActivityTag`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  importStoreLocation(activityUploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/importStoreLocation`, activityUploadedRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getStoreLocationExport(activityId) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      throw "token not have";
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      responseType: "blob",
    };

    var param = { activityId: activityId };
    param = util.parseParams(param);

    return axios
      .get(`/api/activity/storeLocation/export?` + param, config)
      .then((response) => {
        var disposition = response.headers["content-disposition"];
        var fileName = decodeURI(
          disposition.substring(
            disposition.indexOf("filename=") + 9,
            disposition.length
          )
        );
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  syncInstallationDate(activityId) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { activityId: activityId };
    param = util.parseParams(param);
    return axios
      .get(`/api/activity/syncInstallationDate?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  importActivityInstallation(activityUploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(
        `/api/activity/importActivityInstallation`,
        activityUploadedRO,
        config
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getInstallationExport(activityId) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      throw "token not have";
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      responseType: "blob",
    };

    var param = { activityId: activityId };
    param = util.parseParams(param);

    return axios
      .get(`/api/activity/installation/export?` + param, config)
      .then((response) => {
        var disposition = response.headers["content-disposition"];
        var fileName = decodeURI(
          disposition.substring(
            disposition.indexOf("filename=") + 9,
            disposition.length
          )
        );
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  importInstallation(activityUploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/importInstallation`, activityUploadedRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  activityUploadFile(activityUploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/activityUploadFile`, activityUploadedRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  deleteUploadFile(activityDeleteUploadedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/deleteUploadFile`, activityDeleteUploadedRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityStat(activityId, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    var param = { activityId: activityId };
    param = util.parseParams(param);
    return axios
      .get(`/api/activity/stat?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  selectAllActivity(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/activity/selectAllActivity`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  updateActivityTag(updateActivityTagRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/updateActivityTag`, updateActivityTagRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  deleteActivityTag(deleteActivityTagRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/deleteActivityTag`, deleteActivityTagRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  approvedActivityInstallationUploadedAll(activityInstallationApprovedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(
        `/api/activity/approvedActivityInstallationUploadedAll`,
        activityInstallationApprovedRO,
        config
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  approvedActivityInstallationUploaded(activityInstallationUploadedApprovedRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(
        `/api/activity/approvedActivityInstallationUploaded`,
        activityInstallationUploadedApprovedRO,
        config
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  nullifiedActivityInstallationUploaded(
    activityInstallationUploadedNullifieRO
  ) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(
        `/api/activity/nullifiedActivityInstallationUploaded`,
        activityInstallationUploadedNullifieRO,
        config
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityImportUploaded(activityId, retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    var param = { activityId: activityId };
    param = util.parseParams(param);
    return axios
      .get(`/api/activity/getActivityImportUploaded?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  createActivityStats(activityStatsCreate) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/create/stats`, activityStatsCreate, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  addActivityStats(infoDataMap) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/activity/add/stats`, infoDataMap, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityStats() {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };

    return axios
      .get(`/api/activity/getActivityStats`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityStatsItem(itemId) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { itemId: itemId };
    param = util.parseParams(param);
    return axios
      .get(`/api/activity/getActivityStatsItem?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getActivityWeek() {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };

    return axios
      .get(`/api/activity/getActivityWeek`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default activityRequest;
