import {
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
  watch,
  nextTick,
  createVNode,
  h,
  computed,
} from "vue";

export default {
  install(app) {
    app.directive("tinymce", {
      async mounted(el) {
        const script = document.createElement("script");
        script.src = "/tinymce/tinymce.min.js";
        script.onload = () => {
          const proxy = app.config.globalProperties; // 获取全局属性中的proxy

          const getPublicSignature = async (directory) => {
            return new Promise((resolve, reject) => {
              proxy.$api.authorizationRequest
                .getPublicSignature(directory)
                .then((res) => {
                  resolve(res.result.data);
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
            });
          };

          const getSuffix = (filename) => {
            let pos = filename.lastIndexOf(".");
            let suffix = "";
            if (pos != -1) {
              suffix = filename.substring(pos);
            }
            return suffix;
          };

          const globalState = app.config.globalProperties.$globalState;

          tinymce.init({
            target: el,
            width: "100%",
            menubar: false,
            statusbar: false,
            readonly: !globalState.showToolbar,
            font_formats:
              "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino; Times New Roman=times new roman,times; Verdana=verdana,geneva;", // 自定义字体列表
            plugins: [
              "image", // 添加图片插件
              "code", // 添加代码插件
              "table", // 添加表格插件
              "link",
            ],
            toolbar: globalState.showToolbar
              ? "blocks | fontsizeplus fontsizeinput fontsizeminus | bold italic forecolor | \
                  align | \
                  outdent indent | link | image | code | table"
              : false,
            base_url: "/tinymce", // 基础路径
            suffix: ".min", // 后缀
            automatic_uploads: true,
            file_picker_types: "image",
            file_picker_callback: async (cb, value, meta) => {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.onchange = async function () {
                const file = this.files[0];

                // 获取OSS签名
                let credentials;
                try {
                  credentials = await getPublicSignature("interactions_image"); // 使用getSignature函数
                } catch (error) {
                  console.error("Failed to fetch OSS credentials:", error);
                  return;
                }

                const formData = new FormData();
                formData.append(
                  "key",
                  `${credentials.dir}/${credentials.uuid}${getSuffix(
                    file.name
                  )}`
                );
                formData.append("OSSAccessKeyId", credentials.accessId);
                formData.append("policy", credentials.policy);
                formData.append("Signature", credentials.signature);
                formData.append("success_action_status", "200"); // 让服务器返回200状态码
                formData.append("file", file);

                try {
                  const response = await fetch(credentials.host, {
                    method: "POST",
                    body: formData,
                  });

                  if (response.status === 200) {
                    const imageUrl = `${credentials.host}/${credentials.dir}/${
                      credentials.uuid
                    }${getSuffix(file.name)}`;
                    cb(imageUrl, { title: file.name }); // 将图片插入编辑器
                  } else {
                    console.error("OSS upload error:", response.statusText);
                  }
                } catch (err) {
                  console.error("OSS upload error:", err);
                }
              };
              input.click();
            },
            setup: (editor) => {
              editor.ui.registry.addMenuButton("align", {
                icon: "align-center",
                tooltip: "Align text",
                fetch: function (callback) {
                  const items = [
                    {
                      type: "menuitem",
                      icon: "align-left",
                      tooltip: "Align left",
                      onAction: function () {
                        editor.execCommand("JustifyLeft");
                      },
                    },
                    {
                      type: "menuitem",
                      icon: "align-center",
                      tooltip: "Align center",
                      onAction: function () {
                        editor.execCommand("JustifyCenter");
                      },
                    },
                    {
                      type: "menuitem",
                      icon: "align-right",
                      tooltip: "Align right",
                      onAction: function () {
                        editor.execCommand("JustifyRight");
                      },
                    },
                    {
                      type: "menuitem",
                      icon: "align-justify",
                      tooltip: "Justify text",
                      onAction: function () {
                        editor.execCommand("JustifyFull");
                      },
                    },
                  ];
                  callback(items);
                },
              });
              editor.on("dragstart", (e) => {
                if (e.target.nodeName === "IMG") {
                  const img = e.target.cloneNode(true);
                  const originalWidth = img.width;
                  const originalHeight = img.height;
                  const newWidth = 50;
                  const newHeight = (originalHeight / originalWidth) * newWidth;

                  img.style.width = `${newWidth}px`;
                  img.style.height = `${newHeight}px`;
                  img.style.opacity = "0.1";
                  img.style.position = "absolute";
                  img.style.pointerEvents = "none";
                  img.style.top = "-9999px";
                  document.body.appendChild(img);

                  // 计算缩放后的偏移量
                  const scaleX = newWidth / originalWidth;
                  const scaleY = newHeight / originalHeight;
                  const offsetX =
                    (e.clientX - e.target.getBoundingClientRect().left) *
                    scaleX;
                  const offsetY =
                    (e.clientY - e.target.getBoundingClientRect().top) * scaleY;

                  e.dataTransfer.setDragImage(img, offsetX, offsetY);

                  setTimeout(() => {
                    document.body.removeChild(img);
                  }, 0);
                }
              });

              editor.on("init", function () {
                const editorBody = editor.getBody();

                // 为每个图片添加点击事件监听器
                editorBody.addEventListener("click", function (e) {
                  if (e.target.tagName === "IMG") {
                    const modal = document.getElementById("imageModal");
                    console.log("aaa:", modal);
                    console.log(e.target.src);
                  }
                });
              });
            },
          });
        };
        document.head.appendChild(script);
      },
      unmounted(el) {
        if (typeof tinymce !== "undefined" && tinymce.get(el.id)) {
          tinymce.remove(el);
        }
      },
    });
  },
};
