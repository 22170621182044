export default {
  name: "CenteredModal",
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    extraClass: {
      type: String,
      default: ""
    },
    extraStyle: {
      type: [String, Object],
      default: ""
    }
  }
};