import "core-js/modules/es.array.push.js";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { Plus } from "@element-plus/icons-vue";
import util from "@/util/util";
import { useRoute, useRouter } from "vue-router";
import { ElLoading } from "element-plus";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "RepairMessage",
  components: {
    Plus,
    ElLoading
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const nameInput = ref("");
    const mobileInput = ref("");
    const flag = ref("false");
    const dialogImageUrl = ref("");
    const remarkInput = ref("");
    const dialogVisible = ref(false);
    const uploadTable = ref(null);
    const fullscreenLoading = ref(false);
    const uploadTableData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      uploadedList: [],
      storeInfo: {}
    });
    const handlePictureCardPreview = uploadFile => {
      console.log("点击了");
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };

    //获取报修信息
    const selectRepairById = repairId => {
      let repairIdRO = {
        repairId: repairId
      };
      proxy.$api.repairRequest.selectRepairById(repairIdRO).then(function (response) {
        console.log(response);
        if (response.code === "200000") {
          uploadTableData.storeInfo = response.result.data;
          ElNotification({
            title: "成功",
            message: response.result.promptMsg,
            type: "success",
            duration: 1000
          });
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(function (error) {
        console.log(error);
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const goRepairInfo = () => {
      router.push({
        path: "/repair-info",
        query: {
          id: route.query.id
        }
      });
    };
    const login = async () => {
      let params = {
        username: "temp_2024",
        password: "1",
        grant_type: "password"
      };
      return new Promise((resolve, reject) => {
        proxy.$api.userRequest.login(params).then(res => {
          sessionStorage.setItem("token", JSON.stringify(res));
          resolve(true);
        }).catch(error => {
          resolve(false);
        });
      });
    };
    onMounted(async () => {
      if ((await login()) == false) {
        ElNotification({
          title: "失败",
          message: "临时用户验证失败",
          type: "error",
          duration: 3000
        });
        return;
      }
      selectRepairById(route.query.id);
    });
    return {
      nameInput,
      mobileInput,
      remarkInput,
      flag,
      dialogImageUrl,
      dialogVisible,
      uploadTableData,
      uploadTable,
      fullscreenLoading,
      handlePictureCardPreview,
      goRepairInfo
    };
  }
};