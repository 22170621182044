import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-16172836"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    class: "svg-icon",
    style: _normalizeStyle({
      width: $props.iconSize,
      height: $props.iconSize,
      color: $props.iconColor,
      backgroundColor: $props.iconBackgroundColor
    })
  }, [_createElementVNode("use", {
    href: '#icon-' + $props.iconName
  }, null, 8, _hoisted_1)], 4);
}