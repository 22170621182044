import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

import { ElMessageBox, ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/notification/style/css';
export default defineComponent({
  components: {},
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRouter();
    const username = route.currentRoute.value.params.username;
    const code = route.currentRoute.value.params.code;
    const verificationUserEmail = async verificationUserEmailRO => {
      proxy.$api.userRequest.verificationUserEmail(verificationUserEmailRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202012") {
            ElMessageBox.alert(res.result.promptMsg, "提示", {
              confirmButtonText: "确定",
              type: "success"
            }).then(() => {
              route.push({
                path: "/"
              });
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    onMounted(async () => {
      const verificationUserEmailRO = {
        username: username,
        code: code
      };
      verificationUserEmail(verificationUserEmailRO);
    });
    return {};
  }
});