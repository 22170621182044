const permission = {
  haveModule(moduleKey) {
    let userInfo = JSON.parse(
      window.localStorage.getItem("pnp_user_info" || "{}")
    );

    if (
      userInfo == null ||
      userInfo.modules == null ||
      userInfo.modules.length == 0
    ) {
      return false;
    }

    let findIndex = userInfo.modules.findIndex(
      (element) => element.moduleKey == moduleKey
    );
    if (findIndex != -1) {
      return true;
    }

    return false;
  },
  havePermission(moduleKey, permissionKey) {
    let userInfo = JSON.parse(
      window.localStorage.getItem("pnp_user_info" || "{}")
    );

    if (
      userInfo == null ||
      userInfo.modules == null ||
      userInfo.modules.length == 0
    ) {
      return false;
    }

    let moduleIndex = userInfo.modules.findIndex(
      (element) => element.moduleKey == moduleKey
    );
    if (moduleIndex != -1) {
      let module = userInfo.modules[moduleIndex];
      if (
        module.modulePermissions != null &&
        module.modulePermissions.length > 0
      ) {
        let permissionIndex = module.modulePermissions.findIndex(
          (element) => element.modulePermissionKey == permissionKey
        );
        if (permissionIndex != -1) {
          return true;
        }
      }
    }

    return false;
  },
};

export default permission;
