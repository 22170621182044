import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
import { ref, onMounted, nextTick } from "vue";
export default {
  __name: 'Test',
  setup(__props) {
    const handleChange = value => {
      console.log(`selected ${value}`);
    };
    const op = [...Array(25)].map((_, i) => ({
      value: (i + 10).toString(36) + (i + 1),
      label: `Option ${i + 1}`
    }));
    console.log(op);
    const value = ref(["a1", "b2"]);
    onMounted(() => {
      nextTick(() => {
        console.log("Component mounted, forcing update if necessary");
        // 强制触发更新代码可以放在这里
      });
    });

    return (_ctx, _cache) => {
      const _component_a_select = _resolveComponent("a-select");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_select, {
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => value.value = $event),
        mode: "multiple",
        style: {
          "width": "100%"
        },
        placeholder: "Please select",
        options: _unref(op),
        onChange: handleChange,
        optionFilterProp: 'label'
      }, null, 8, ["value", "options"])]);
    };
  }
};