import { defineComponent, reactive, ref, onMounted, onUnmounted, computed, getCurrentInstance, inject } from "vue";
import Tailoring from "../components/Tailoring.vue";
import SvgIcon from "../components/SvgIcon.vue";
import { useRouter } from "vue-router";
import permission from "@/util/permission";
import util from "@/util/util";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default defineComponent({
  name: "UserModify",
  components: {
    SvgIcon,
    Tailoring
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const mobileForm = ref(null);
    const emailForm = ref(null);
    const passwordForm = ref(null);
    const lastNameInput = ref(null);
    const firstNameInput = ref(null);
    const emailInput = ref(null);
    const addressInput = ref(null);
    const mobileInput = ref(null);
    const globalUser = inject("globalUser");
    const userData = reactive({
      userOrganization: {},
      userModifyRo: {
        id: null,
        lastName: null,
        firstName: null,
        avatar: null,
        mobile: null,
        smsCode: null,
        email: null,
        emailCode: null,
        address: null,
        password: null,
        oldPassword: null
      },
      mobileFormRules: {
        mobile: [{
          required: true,
          message: "请输入手机号码",
          trigger: "blur"
        }, {
          min: 11,
          max: 11,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        smsCode: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }, {
          min: 6,
          max: 6,
          message: "请输入正确的验证码",
          trigger: "blur"
        }]
      },
      mobileFormData: {
        mobile: null,
        smsCode: null
      },
      mobileDialogVisible: false,
      emailFormRules: {
        email: [{
          required: true,
          message: "请输入邮箱",
          trigger: "blur"
        }, {
          pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
          message: "请输入有效的邮箱",
          trigger: "blur"
        }],
        emailCode: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }, {
          min: 6,
          max: 6,
          message: "请输入正确的验证码",
          trigger: "blur"
        }]
      },
      emailFormData: {
        email: null,
        emailCode: null
      },
      emailDialogVisible: false,
      lastNameEdit: false,
      firstNameEdit: false,
      addressEdit: false,
      emailEdit: false,
      avatarDialogVisible: false,
      passwordFormRules: {
        oldPassword: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,}$/,
          message: "密码至少8位，并且包含英文大小写字母",
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            if (userData.passwordFormData.passwordAgain != null && userData.passwordFormData.passwordAgain != "" && userData.passwordFormData.passwordAgain != userData.passwordFormData.password) {
              callback(new Error("两次输入的密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }],
        passwordAgain: [{
          required: true,
          message: "请再次输入密码",
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,}$/,
          message: "密码至少8位，并且包含英文大小写字母",
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            if (userData.passwordFormData.password != null && userData.passwordFormData.password != "" && userData.passwordFormData.passwordAgain != userData.passwordFormData.password) {
              callback(new Error("两次输入的密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }]
      },
      passwordFormData: {
        oldPassword: null,
        password: null,
        passwordAgain: null
      },
      passwordDialogVisible: false
    });
    const contenTab = ref(1);
    const getUserOrganization = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.userRequest.getUserOrganization(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    onMounted(async () => {
      let userOrganization = await getUserOrganization();
      userData.userOrganization = userOrganization;
      userData.userModifyRo = {
        id: userOrganization.id,
        lastName: userOrganization.lastName,
        firstName: userOrganization.firstName,
        avatar: userOrganization.avatar,
        mobile: userOrganization.mobile,
        smsCode: null,
        email: userOrganization.email,
        address: userOrganization.address,
        password: null,
        oldPassword: null
      };
    });
    const editLastNameClick = () => {
      userData.lastNameEdit = true;
      lastNameInput.value.focus();
    };
    const editFirstNameClick = () => {
      userData.firstNameEdit = true;
      firstNameInput.value.focus();
    };
    const editEmailClick = () => {
      emailForm.value.resetFields();
      userData.emailDialogVisible = true;
      emailInput.value.focus();
    };
    const editAddressClick = () => {
      userData.addressEdit = true;
      addressInput.value.focus();
    };
    const imgUrlbase64 = code => {
      userData.userModifyRo.avatar = code;
      userData.avatarDialogVisible = false;
    };
    const editMobileClick = () => {
      mobileForm.value.resetFields();
      userData.mobileDialogVisible = true;
      mobileInput.value.focus();
    };
    const editPasswordClick = () => {
      passwordForm.value.resetFields();
      userData.passwordDialogVisible = true;
    };
    const smsModifyControl = reactive({
      smsCooldownSecond: 60,
      smsDisabled: false,
      smsButtonText: "发送验证码"
    });
    const smsModifyCooldown = () => {
      smsModifyControl.smsCooldownHandle = setInterval(function () {
        smsModifyControl.smsCooldownSecond--;
        if (smsModifyControl.smsCooldownSecond == 0) {
          smsModifyControl.smsDisabled = false;
          clearInterval(smsModifyControl.smsCooldownHandle);
          smsModifyControl.smsButtonText = "发送验证码";
        } else {
          smsModifyControl.smsButtonText = smsModifyControl.smsCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const onSendModifySms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(userData.mobileFormData.mobile) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的手机号码",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterVerificationCode(userData.mobileFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查看手机",
              type: "success",
              duration: 3000
            });
            smsModifyControl.smsCooldownSecond = 60;
            smsModifyControl.smsDisabled = true;
            smsModifyControl.smsButtonText = smsModifyControl.smsCooldownSecond + "秒";
            smsModifyCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const emailModifyControl = reactive({
      emailCooldownSecond: 60,
      emailDisabled: false,
      emailButtonText: "发送验证码"
    });
    const emailModifyCooldown = () => {
      emailModifyControl.emailCooldownHandle = setInterval(function () {
        emailModifyControl.emailCooldownSecond--;
        if (emailModifyControl.emailCooldownSecond == 0) {
          emailModifyControl.emailDisabled = false;
          clearInterval(emailModifyControl.emailCooldownHandle);
          emailModifyControl.emailButtonText = "发送验证码";
        } else {
          emailModifyControl.emailButtonText = emailModifyControl.emailCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const onSendModifyEmail = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(userData.emailFormData.email) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的邮箱",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterEmailCode(userData.emailFormData.email).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201105") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查收邮箱",
              type: "success",
              duration: 3000
            });
            emailModifyControl.emailCooldownSecond = 60;
            emailModifyControl.emailDisabled = true;
            emailModifyControl.emailButtonText = emailModifyControl.emailCooldownSecond + "秒";
            emailModifyCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };

    // const confirmSaveMobile = () => {
    //   mobileForm.value.validate((valid) => {
    //     if (valid) {
    //       proxy.$api.authorizationRequest
    //         .checkRegisterVerificationCode(
    //           userData.mobileFormData.mobile,
    //           userData.mobileFormData.smsCode
    //         )
    //         .then((res) => {
    //           if (res.code === "200000") {
    //             if (res.result.dataCode === "201103") {
    //               userData.mobileDialogVisible = false;
    //               userData.userModifyRo.mobile = userData.mobileFormData.mobile;
    //               userData.userModifyRo.smsCode =
    //                 userData.mobileFormData.smsCode;
    //               userData.mobileFormData.mobile = null;
    //               userData.mobileFormData.smsCode = null;
    //             } else {
    //               ElNotification({
    //                 title: "失败",
    //                 message: res.result.promptMsg,
    //                 type: "error",
    //                 duration: 6000,
    //               });
    //             }
    //           } else {
    //             ElNotification({
    //               title: "失败",
    //               message: res.msg,
    //               type: "error",
    //               duration: 6000,
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           ElNotification({
    //             title: "失败",
    //             message: "验证失败",
    //             type: "error",
    //             duration: 6000,
    //           });
    //         });
    //     }
    //   });
    // };

    const confirmSaveMobile = () => {
      mobileForm.value.validateFields().then(() => {
        let changeUserMobileRO = {
          mobile: userData.mobileFormData.mobile,
          smsCode: userData.mobileFormData.smsCode
        };
        changeUserMobile(changeUserMobileRO);
        // proxy.$api.authorizationRequest
        //   .checkRegisterVerificationCode(
        //     userData.mobileFormData.mobile,
        //     userData.mobileFormData.smsCode
        //   )
        //   .then((res) => {
        //     if (res.code === "200000") {
        //       if (res.result.dataCode === "201103") {
        //         userData.mobileDialogVisible = false;
        //         userData.userModifyRo.mobile = userData.mobileFormData.mobile;
        //         userData.userModifyRo.smsCode =
        //           userData.mobileFormData.smsCode;
        //         userData.mobileFormData.mobile = null;
        //         userData.mobileFormData.smsCode = null;
        //         ElNotification({
        //           title: "成功",
        //           // message: res.result.promptMsg,
        //           message: "修改成功",
        //           type: "success",
        //           duration: 6000,
        //         });
        //       } else {
        //         ElNotification({
        //           title: "失败",
        //           message: res.result.promptMsg,
        //           type: "error",
        //           duration: 6000,
        //         });
        //       }
        //     } else {
        //       ElNotification({
        //         title: "失败",
        //         message: res.msg,
        //         type: "error",
        //         duration: 6000,
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     ElNotification({
        //       title: "失败",
        //       message: "验证失败",
        //       type: "error",
        //       duration: 6000,
        //     });
        //   });
      }).catch(errors => {
        // 处理表单验证错误
        console.error(errors);
      });
    };

    // const confirmSaveEmail = () => {
    //   emailForm.value.validate((valid) => {
    //     if (valid) {
    //       proxy.$api.authorizationRequest
    //         .checkRegisterEmailCode(
    //           userData.emailFormData.email,
    //           userData.emailFormData.emailCode
    //         )
    //         .then((res) => {
    //           if (res.code === "200000") {
    //             if (res.result.dataCode === "201109") {
    //               userData.emailDialogVisible = false;
    //               userData.userModifyRo.email = userData.emailFormData.email;
    //               userData.userModifyRo.emailCode =
    //                 userData.emailFormData.emailCode;
    //               userData.emailFormData.email = null;
    //               userData.emailFormData.emailCode = null;
    //             } else {
    //               ElNotification({
    //                 title: "失败",
    //                 message: res.result.promptMsg,
    //                 type: "error",
    //                 duration: 6000,
    //               });
    //             }
    //           } else {
    //             ElNotification({
    //               title: "失败",
    //               message: res.msg,
    //               type: "error",
    //               duration: 6000,
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           ElNotification({
    //             title: "失败",
    //             message: "验证失败",
    //             type: "error",
    //             duration: 6000,
    //           });
    //         });
    //     }
    //   });
    // };

    const confirmSaveEmail = () => {
      emailForm.value.validateFields().then(() => {
        let changeUserEmailRO = {
          email: userData.emailFormData.email,
          emailCode: userData.emailFormData.emailCode
        };
        changeUserEmail(changeUserEmailRO);
        // proxy.$api.authorizationRequest
        //   .checkRegisterEmailCode(
        //     userData.emailFormData.email,
        //     userData.emailFormData.emailCode
        //   )
        //   .then((res) => {
        //     if (res.code === "200000") {
        //       if (res.result.dataCode === "201109") {
        //         userData.emailDialogVisible = false;
        //         userData.userModifyRo.email = userData.emailFormData.email;
        //         userData.userModifyRo.emailCode =
        //           userData.emailFormData.emailCode;
        //         userData.emailFormData.email = null;
        //         userData.emailFormData.emailCode = null;
        //         ElNotification({
        //           title: "成功",
        //           // message: res.result.promptMsg,
        //           message: "修改成功",
        //           type: "success",
        //           duration: 6000,
        //         });
        //       } else {
        //         ElNotification({
        //           title: "失败",
        //           message: res.result.promptMsg,
        //           type: "error",
        //           duration: 6000,
        //         });
        //       }
        //     } else {
        //       ElNotification({
        //         title: "失败",
        //         message: res.msg,
        //         type: "error",
        //         duration: 6000,
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     ElNotification({
        //       title: "失败",
        //       message: "验证失败",
        //       type: "error",
        //       duration: 6000,
        //     });
        //   });
      }).catch(errors => {
        // 处理表单验证错误
        console.error(errors);
      });
    };

    // const confirmSavePassword = () => {
    //   passwordForm.value.validate((valid) => {
    //     if (valid) {
    //       const userPasswordRO = {
    //         password: userData.passwordFormData.oldPassword,
    //       };
    //       proxy.$api.userRequest
    //         .verificationUserPassword(userPasswordRO)
    //         .then((res) => {
    //           if (res.code === "200000") {
    //             if (res.result.dataCode === "202017") {
    //               userData.userModifyRo.oldPassword =
    //                 userData.passwordFormData.oldPassword;
    //               userData.userModifyRo.password =
    //                 userData.passwordFormData.password;

    //               userData.passwordFormData.oldPassword = null;
    //               userData.passwordFormData.password = null;
    //               userData.passwordFormData.passwordAgain = null;
    //               userData.passwordDialogVisible = false;
    //             } else {
    //               ElNotification({
    //                 title: "失败",
    //                 message: res.result.promptMsg,
    //                 type: "error",
    //                 duration: 6000,
    //               });
    //             }
    //           } else {
    //             ElNotification({
    //               title: "失败",
    //               message: res.msg,
    //               type: "error",
    //               duration: 6000,
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           ElNotification({
    //             title: "失败",
    //             message: "验证失败",
    //             type: "error",
    //             duration: 6000,
    //           });
    //         });
    //     }
    //   });
    // };

    const confirmSavePassword = () => {
      passwordForm.value.validateFields().then(() => {
        let changeUserPasswordRO = {
          password: userData.passwordFormData.password,
          oldPassword: userData.passwordFormData.oldPassword
        };
        changeUserPassword(changeUserPasswordRO);
        // const userPasswordRO = {
        //   password: userData.passwordFormData.oldPassword,
        // };
        // proxy.$api.userRequest
        //   .verificationUserPassword(userPasswordRO)
        //   .then((res) => {
        //     if (res.code === "200000") {
        //       if (res.result.dataCode === "202017") {
        //         userData.userModifyRo.oldPassword =
        //           userData.passwordFormData.oldPassword;
        //         userData.userModifyRo.password =
        //           userData.passwordFormData.password;

        //         userData.passwordFormData.oldPassword = null;
        //         userData.passwordFormData.password = null;
        //         userData.passwordFormData.passwordAgain = null;
        //         userData.passwordDialogVisible = false;
        //         ElNotification({
        //           title: "成功",
        //           // message: res.result.promptMsg,
        //           message: "修改成功",
        //           type: "success",
        //           duration: 6000,
        //         });
        //       } else {
        //         ElNotification({
        //           title: "失败",
        //           message: res.result.promptMsg,
        //           type: "error",
        //           duration: 6000,
        //         });
        //       }
        //     } else {
        //       ElNotification({
        //         title: "失败",
        //         message: res.msg,
        //         type: "error",
        //         duration: 6000,
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     ElNotification({
        //       title: "失败",
        //       message: "验证失败",
        //       type: "error",
        //       duration: 6000,
        //     });
        //   });
      }).catch(errors => {
        // 处理表单验证错误
        console.error(errors);
      });
    };
    const confirmSaveUser = () => {
      if (userData.userOrganization.lastName == userData.userModifyRo.lastName && userData.userOrganization.firstName == userData.userModifyRo.firstName && userData.userOrganization.avatar == userData.userModifyRo.avatar && userData.userOrganization.mobile == userData.userModifyRo.mobile && userData.userOrganization.email == userData.userModifyRo.email && userData.userOrganization.address == userData.userModifyRo.address && userData.userModifyRo.oldPassword == null) {
        ElNotification({
          title: "失败",
          message: "没有任何修改",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.userRequest.modifyUser(userData.userModifyRo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202019") {
            let userOrganization = res.result.data;
            userData.userOrganization = userOrganization;
            userData.userModifyRo = {
              id: userOrganization.id,
              lastName: userOrganization.lastName,
              firstName: userOrganization.firstName,
              avatar: userOrganization.avatar,
              mobile: userOrganization.mobile,
              smsCode: null,
              email: userOrganization.email,
              emailCode: null,
              address: userOrganization.address,
              password: null,
              oldPassword: null
            };
            let userInfoData = util.getUserInfo();
            userInfoData.lastName = userOrganization.lastName;
            userInfoData.firstName = userOrganization.firstName;
            userInfoData.avatar = userOrganization.avatar;
            userInfoData.mobile = userOrganization.mobile;
            userInfoData.email = userOrganization.email;
            userInfoData.managerId = userOrganization.managerId;
            userInfoData.type = userOrganization.type;
            window.localStorage.setItem("pnp_user_info", JSON.stringify(userInfoData));
            globalUser.avatar = userInfoData.avatar;
            globalUser.email = userInfoData.email;
            globalUser.firstName = userInfoData.firstName;
            globalUser.lastName = userInfoData.lastName;
            globalUser.managerId = userInfoData.managerId;
            globalUser.type = userInfoData.type;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "修改失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const cancelSaveUser = () => {
      router.go(-1);
    };
    const changeUserMobile = changeUserMobileRO => {
      proxy.$api.userRequest.changeUserMobile(changeUserMobileRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202024") {
            userData.userModifyRo.email = userData.emailFormData.email;
            userData.userModifyRo.emailCode = userData.emailFormData.emailCode;
            userData.emailFormData.email = null;
            userData.emailFormData.emailCode = null;
            userData.mobileDialogVisible = false;
            ElNotification({
              title: "成功",
              // message: res.result.promptMsg,
              message: "修改成功",
              type: "success",
              duration: 6000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const changeUserEmail = changeUserEmailRO => {
      proxy.$api.userRequest.changeUserEmail(changeUserEmailRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202026") {
            userData.emailDialogVisible = false;
            userData.userModifyRo.email = userData.emailFormData.email;
            userData.userModifyRo.emailCode = userData.emailFormData.emailCode;
            userData.emailFormData.email = null;
            userData.emailFormData.emailCode = null;
            ElNotification({
              title: "成功",
              // message: res.result.promptMsg,
              message: "修改成功",
              type: "success",
              duration: 6000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const changeUserPassword = changeUserPasswordRO => {
      proxy.$api.userRequest.changeUserPassword(changeUserPasswordRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202028") {
            userData.userModifyRo.oldPassword = userData.passwordFormData.oldPassword;
            userData.userModifyRo.password = userData.passwordFormData.password;
            userData.passwordFormData.oldPassword = null;
            userData.passwordFormData.password = null;
            userData.passwordFormData.passwordAgain = null;
            userData.passwordDialogVisible = false;
            ElNotification({
              title: "成功",
              // message: res.result.promptMsg,
              message: "修改成功",
              type: "success",
              duration: 6000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证失败",
          type: "error",
          duration: 6000
        });
      });
    };
    return {
      lastNameInput,
      firstNameInput,
      emailInput,
      addressInput,
      mobileInput,
      mobileForm,
      emailForm,
      passwordForm,
      userData,
      permission,
      editLastNameClick,
      editFirstNameClick,
      editEmailClick,
      editAddressClick,
      editMobileClick,
      editPasswordClick,
      imgUrlbase64,
      smsModifyControl,
      onSendModifySms,
      emailModifyControl,
      onSendModifyEmail,
      confirmSaveMobile,
      confirmSaveEmail,
      confirmSavePassword,
      confirmSaveUser,
      cancelSaveUser,
      //2.0
      contenTab
    };
  }
});