import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { SuccessFilled } from "@element-plus/icons-vue";
import util from "@/util/util";
import { useRouter, useRoute } from "vue-router";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "WxCheck",
  components: {
    SuccessFilled
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const code = route.query.code;
    const checkUserByWxCode = async code => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.checkUserByWxCode(code).then(res => {
          resolve(res);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    onMounted(async () => {
      let res = await checkUserByWxCode(code);
      if (res.code === "200000") {
        if (res.result.dataCode === "201119") {
          window.localStorage.setItem("union_token", JSON.stringify(res.result.data));
        }
      }
    });
    return {};
  }
};