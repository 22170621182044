import { Modal, Button, Divider } from "ant-design-vue";
import { h } from "vue";
import SvgIcon from "../components/SvgIcon.vue";

const showConfirmModal = ({
  title = "询问",
  content = "是否要删除记录",
  okText = "确定",
  cancelText = "取消",
  onOk = () => {},
}) => {
  const modal = Modal.confirm({
    title: () =>
      h("div", { style: { display: "flex" } }, [
        h("div", title),
        h(Divider, {
          style: { margin: "10px 30px 30px 20px", minWidth: "0px", flex: 1 },
        }),
      ]),
    icon: null,
    content: () =>
      h(
        "div",
        {
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        [
          h(SvgIcon, {
            iconName: "warning",
            iconSize: "28px",
          }),
          h("div", content),
        ]
      ),
    zIndex: 1001,
    okButtonProps: { class: "sts-button danger", style: { height: "22px" } },
    cancelButtonProps: {
      class: "sts-button default",
      style: { height: "22px" },
    },
    footer: () =>
      h(
        "div",
        { style: { display: "flex", alignItems: "center", marginTop: "20px" } },
        [
          h(Divider, {
            style: { margin: "10px 30px 10px 0px", minWidth: "0px" },
          }),
          h(
            Button,
            {
              class: "sts-button default",
              key: "back",
              onClick: () => modal.destroy(),
            },
            cancelText
          ),
          h(
            Button,
            {
              class: "sts-button danger",
              style: { marginLeft: "10px" },
              key: "submit",
              type: "primary",
              onClick: async () => {
                await onOk();
                modal.destroy();
              },
            },
            okText
          ),
        ]
      ),
  });
};

export default showConfirmModal;
