import "core-js/modules/es.array.push.js";
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import util from "@/util/util";
import { genFileId } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import ExDialog from "../components/ExDialog.vue";
import permission from "@/util/permission";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: "ConveyPlan",
  components: {
    SvgIcon,
    ExDialog
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const {
      toClipboard
    } = useClipboard();
    const router = useRouter();
    const route = useRoute();
    const activityId = route.params.activityId;
    const vendorId = route.params.vendorId;
    const uploadConverPane = ref(null);
    const converTable = ref();
    const ExDialogShow = ref(false);
    // 分页
    const converPaginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });
    // 安装进度列表
    const converData = reactive([]);
    const storeTypeData = reactive([]);
    const vendorData = reactive([]);
    const vendorTypeData = reactive([]);
    const expressStateListData = reactive([]);
    const logisticsTypeData = reactive([]);
    const uploadConverData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const uploadConverPopoverVisible = ref(false);
    const converQuery = reactive({
      typeIndex: 1,
      insValue: "",
      insSelect: {
        storeType: null,
        vendorId: null,
        logisticsTypeId: null,
        state: null,
        areaInput: ""
      }
    });
    //分页Select变化
    const paginationSizeConverChange = val => {
      let data = {
        searchKeyword: converQuery.insValue,
        activityId: converQuery.insSelect.activityId,
        storeTypeId: converQuery.insSelect.storeType,
        vendorId: converQuery.insSelect.vendorId,
        city: converQuery.insSelect.areaInput,
        logisticsTypeId: converQuery.insSelect.logisticsTypeId,
        expressStateId: converQuery.insSelect.state
      };
      getLogisticsPageApi(data, converPaginationData.currentPage, converPaginationData.pageSize);
    };
    //分页页数变化
    const paginationCurrentConverChange = val => {
      let data = {
        searchKeyword: converQuery.insValue,
        activityId: converQuery.insSelect.activityId,
        storeTypeId: converQuery.insSelect.storeType,
        vendorId: converQuery.insSelect.vendorId,
        city: converQuery.insSelect.areaInput,
        logisticsTypeId: converQuery.insSelect.logisticsTypeId,
        expressStateId: converQuery.insSelect.state
      };
      getLogisticsPageApi(data, converPaginationData.currentPage, converPaginationData.pageSize);
    };

    //btn点击事件
    const openUploadConverPopover = () => {
      uploadConverPane.value.clearFiles();
      uploadConverPopoverVisible.value = true;
    };
    //当超出限制时，执行的钩子函数
    const handleConverExceed = files => {
      uploadConverPane.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadConverPane.value.handleStart(file);
      uploadConverPane.value.submit();
    };
    //获取文件签名等信息
    const getSignature = async directory => {
      console.log(directory);
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    //上传文件之前的钩子
    const handleConverBeforeUpload = async file => {
      uploadConverData.signatureData = await getSignature("excel/temp");
      let filename = file.name;
      let fullName = uploadConverData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadConverData.signatureData.dir + fullName;
      uploadConverData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadConverData.signatureData.policy,
        OSSAccessKeyId: uploadConverData.signatureData.accessId,
        signature: uploadConverData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadConverData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    //文件上传成功的钩子
    const handleConverOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadConverData.data.name,
          uploadedName: uploadConverData.data.key
        };
        console.log(uploadedRO);
        importConverRequest(uploadedRO);
      }
    };
    //文件上传成功发送import请求
    const importConverRequest = async uploadedRO => {
      proxy.$api.scheduleRequest.importLogistics(uploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202111") {
            uploadConverPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const converLoading = ref(false);
    //时间戳转时间
    const getDate = data => {
      var date = new Date(data);
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    };
    //表头排序
    const headerCellConverClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = converTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    //获取发货进度列表
    const getLogisticsPageApi = async (data, pageIndex, pageSize, add) => {
      converLoading.value = true;
      let logisticsPageRO = {
        data: data,
        pageIndex: pageIndex,
        pageSize: pageSize
      };
      proxy.$api.scheduleRequest.getLogisticsPage(logisticsPageRO).then(res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202250") {
            let msg = res.result.data.pageList;
            if (msg) {
              if (add) {
                converData.push(...msg);
                converPaginationData.totalSize = res.result.data.totalCount;
              } else {
                converData.length = 0;
                converData.push(...msg);
                converPaginationData.totalSize = res.result.data.totalCount;
              }
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        converLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        converLoading.value = false;
      });
    };
    // 获取所有店铺类型
    const getStoreType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商类型
    const getVendorType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有物流状态
    const getExpressState = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getExpressState(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有确认状态
    const getLogisticsType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getLogisticsType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 根据供应商id获取供应商名字
    const getName = (type, id) => {
      if (type === "storeTypeId") {
        for (let i = 0; i < storeTypeData.length; i++) {
          if (storeTypeData[i].storeTypeId === id) {
            return storeTypeData[i].storeTypeNameZh;
          }
        }
      }
      if (type === "vendorTypeId") {
        for (let i = 0; i < vendorTypeData.length; i++) {
          if (vendorTypeData[i].vendorTypeId === id) {
            return vendorTypeData[i].vendorTypeNameZh;
          }
        }
      }
    };
    //搜索点击事件
    const searchClick = () => {
      let data = {
        searchKeyword: converQuery.insValue,
        activityId: converQuery.insSelect.activityId,
        storeTypeId: converQuery.insSelect.storeType,
        vendorId: converQuery.insSelect.vendorId,
        city: converQuery.insSelect.areaInput,
        logisticsTypeId: converQuery.insSelect.logisticsTypeId,
        expressStateId: converQuery.insSelect.state
      };
      getLogisticsPageApi(data, converPaginationData.currentPage, converPaginationData.pageSize);
    };
    // 店铺信息复制
    const copyStore = async row => {
      let text = "地址：" + row.storeAddress + "\n" + "联系人：" + row.storeContactName + "\n" + "电话：" + row.storeContactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    // 供应商信息复制
    const copyVendor = async row => {
      let text = "联系人：" + row.contactName + "\n" + "电话：" + row.contactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    // 验收信息复制
    const copyResult = async row => {
      let text = "联系人：" + row.contactName + "\n" + "电话：" + row.contactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    //导出
    const openExportEvent = () => {
      const $table = converTable.value;
      if ($table) {
        $table.exportData({
          filename: "导出的物流表",
          type: "csv"
        });
      }
    };
    const converTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(converPaginationData.totalSize / converPaginationData.pageSize);
          console.log("-------------------" + converPaginationData.currentPage < totalCount);
          if (converPaginationData.currentPage < totalCount) {
            console.log("翻页");
            converPaginationData.currentPage++;
            let data = {
              searchKeyword: converQuery.insValue,
              activityId: converQuery.insSelect.activityId,
              storeTypeId: converQuery.insSelect.storeType,
              vendorId: converQuery.insSelect.vendorId,
              city: converQuery.insSelect.areaInput,
              logisticsTypeId: converQuery.insSelect.logisticsTypeId,
              expressStateId: converQuery.insSelect.state
            };
            getLogisticsPageApi(data, converPaginationData.currentPage, converPaginationData.pageSize, "add");
          }
        }
      }
    };
    // const goInstallPlan = () => {
    //   if (vendorId) {
    //     router.push({ path: "/install-plan/" + activityId + "/" + vendorId });
    //   } else {
    //     router.push({ path: "/install-plan/" + activityId });
    //   }
    // };
    onMounted(async () => {
      let windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (windowWidth <= 768) {
        converPaginationData.pageSize = 20;
      }
      if (vendorId) {
        converQuery.insSelect.vendorId = parseInt(vendorId);
      }
      if (activityId) {
        converQuery.insSelect.activityId = parseInt(activityId);
      }
      //获取安装进度列表
      let data = {
        searchKeyword: converQuery.insValue,
        activityId: converQuery.insSelect.activityId,
        storeTypeId: converQuery.insSelect.storeType,
        vendorId: converQuery.insSelect.vendorId,
        city: converQuery.insSelect.areaInput,
        logisticsTypeId: converQuery.insSelect.logisticsTypeId,
        expressStateId: converQuery.insSelect.state
      };
      getLogisticsPageApi(data, converPaginationData.currentPage, converPaginationData.pageSize);
      // 获取所有店铺类型
      let storeTypeList = await getStoreType();
      if (storeTypeList == null) {
        return;
      }
      storeTypeData.push(...storeTypeList);
      // 获取所有供应商
      let vendorList = await getVendor();
      if (vendorList == null) {
        return;
      }
      vendorData.push(...vendorList);
      // 获取所有供应商类型
      let vendorTypeList = await getVendorType();
      if (vendorTypeList == null) {
        return;
      }
      vendorTypeData.push(...vendorTypeList);
      // 获取所有物流状态
      let getExpressStateList = await getExpressState();
      if (getExpressStateList == null) {
        return;
      }
      for (let i = 0; i < getExpressStateList.length; i++) {
        if (parseInt(getExpressStateList[i].expressStateId) <= 10) {
          expressStateListData.push(getExpressStateList[i]);
        }
      }
      // expressStateListData.push(...getExpressStateList);
      // 获取所有物流类型
      let getLogisticsTypeList = await getLogisticsType();
      if (getLogisticsTypeList == null) {
        return;
      }
      logisticsTypeData.push(...getLogisticsTypeList);
    });
    const deliveredLogistics = row => {
      ElMessageBox.confirm("请确认是否已送达", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deliveredLogisticsRequest(row);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除"
        });
      });
    };
    const deliveredLogisticsRequest = async row => {
      proxy.$api.scheduleRequest.deliveredLogistics(row.logisticsId).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202264") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            row.expressStateName = res.result.data.expressStateName;
            row.expressStateId = res.result.data.expressStateListData;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    return {
      permission,
      router,
      ExDialogShow,
      converQuery,
      converData,
      converTable,
      converPaginationData,
      uploadConverPane,
      uploadConverPopoverVisible,
      uploadConverData,
      converLoading,
      storeTypeData,
      vendorData,
      vendorTypeData,
      expressStateListData,
      logisticsTypeData,
      paginationSizeConverChange,
      paginationCurrentConverChange,
      openUploadConverPopover,
      handleConverExceed,
      handleConverBeforeUpload,
      handleConverOnSuccess,
      getDate,
      headerCellConverClickEvent,
      searchClick,
      getName,
      copyStore,
      copyVendor,
      copyResult,
      openExportEvent,
      converTableScroll,
      activityId,
      vendorId,
      deliveredLogistics
    };
  }
};