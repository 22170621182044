import "core-js/modules/es.array.push.js";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { Plus, Delete, ZoomIn, Check, Close } from "@element-plus/icons-vue";
import util from "@/util/util";
import { useRouter, useRoute } from "vue-router";
import { ElLoading } from "element-plus";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "InstallInfo",
  components: {
    Plus,
    Delete,
    ZoomIn,
    ElLoading,
    Check,
    Close
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const value = ref("");
    const demo = reactive([]);
    const dialogImageUrl = ref("");
    const ActivityName = ref("");
    const vendorName = ref("");
    const dialogVisible = ref(false);
    const textarea1 = ref("");
    const uploadTable = ref(null);
    const uploadTableData = reactive([]);
    const installationStatusData = reactive([]);
    const getVendorData = reactive([]);
    const fullscreenLoading = ref(false);
    const storeCodeSelect = ref(null);
    const storeData = reactive([]);
    const activityId = parseInt(route.params.activityId);
    const vendorId = parseInt(route.params.vendorId);
    // 上传图片数量限制
    const maxNum = 3;
    //获取文件签名等信息
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          reject(error);
        });
      });
    };
    //上传文件之前
    const handleBefore = async (file, index) => {
      console.log(file, index);
      uploadTableData[index].signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadTableData[index].signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadTableData[index].signatureData.dir + fullName;
      let uploadData = {
        name: file.name,
        key: keyValue,
        policy: uploadTableData[index].signatureData.policy,
        OSSAccessKeyId: uploadTableData[index].signatureData.accessId,
        signature: uploadTableData[index].signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadTableData[index].data = uploadData;
      uploadTableData[index].lastUpload = {
        uid: file.uid,
        filename: fullName
      };
      file.uploadData = uploadData;
    };
    // 文件上传成功
    const handleStoreOnSuccess = async (response, file, files, index) => {
      console.log(file);
      if (file.status === "success") {
        let uploadData = file.raw.uploadData;
        let uploadedRO = {
          fileName: uploadData.name,
          uploadedName: uploadData.key,
          uid: file.uid
        };
        if (demo[index].installationStatusId == 5) {
          addInstallationPhoto(index, uploadedRO);
        } else {
          file.uploadedRO = uploadedRO;
          if (demo[index].uploadedList) {
            demo[index].uploadedList.push(uploadedRO);
          } else {
            demo[index].uploadedList = [];
            demo[index].uploadedList.push(uploadedRO);
          }
        }
      }
    };
    //超出文件限制数量
    const handleExceed = () => {
      ElNotification({
        title: "失败",
        message: "最多上传" + maxNum + "张图片",
        type: "error",
        duration: 3000
      });
    };
    //请求获取所有安装点位列表
    const activityInstallationROPage = () => {
      let activityInstallationROPage = {
        activityId: activityId,
        storeCode: storeCodeSelect.value,
        vendorId: vendorId
      };
      proxy.$api.repairRequest.getActivityInstallation(activityInstallationROPage).then(function (response) {
        demo.length = 0;
        demo.push(...response.result.data);
        uploadTableData.length = 0;
        for (var i = 0; i < response.result.data.length; i++) {
          let demoJson = {
            signatureData: {
              accessId: null,
              policy: null,
              signature: null,
              dir: null,
              host: "",
              expire: null,
              uuid: null
            },
            id: i,
            fileList: response.result.data[i].uploaded,
            data: {},
            lastUpload: {},
            cacheData: []
          };
          if (Array.isArray(demoJson.fileList)) {
            for (let uploaded of demoJson.fileList) {
              uploaded.installationStatusId = response.result.data[i].installationStatusId;
            }
          }
          uploadTableData.push(demoJson);
        }
      }).catch(function (error) {
        console.log(error);
      });
    };
    // 点击下一步发送请求
    const sumbitStoreLocation = () => {
      //参数
      let installtionSubmitRO = {
        activityId: activityId,
        storeCode: storeCodeSelect.value,
        vendorId: vendorId,
        uploadPhotos: []
      };
      if (installtionSubmitRO.storeCode == null) {
        ElNotification({
          title: "失败",
          message: "未选门店！",
          type: "error",
          duration: 3000
        });
        return;
      }
      fullscreenLoading.value = true;
      for (var j = 0; j < demo.length; j++) {
        let addObj = {
          activityInstallationId: demo[j].activityInstallationId,
          photos: demo[j].uploadedList
        };
        installtionSubmitRO.uploadPhotos.push(addObj);
      }
      console.log(installtionSubmitRO);
      let guard = true;
      for (let y in installtionSubmitRO.uploadPhotos) {
        if (installtionSubmitRO.uploadPhotos[y].photos) {
          guard = false;
          break;
        }
      }
      if (guard) {
        fullscreenLoading.value = false;
        router.push({
          path: "/install-check/" + activityId + "/" + vendorId + "/" + storeCodeSelect.value
        });
      }
      //如果有新增图片  发送请求。
      //如没有新增图片，直接跳转页面。
      let flag = false;
      for (let l in demo) {
        if (demo[l].installationStatusId !== 5) {
          flag = true;
          break;
        }
      }
      if (flag) {
        proxy.$api.repairRequest.submitStoreLocation(installtionSubmitRO).then(function (response) {
          if (response.code === "200000") {
            ElNotification({
              title: "成功",
              message: response.result.promptMsg,
              type: "success",
              duration: 3000
            });
            fullscreenLoading.value = false;
            router.push({
              path: "/install-check/" + activityId + "/" + vendorId + "/" + storeCodeSelect.value
            });
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 3000
            });
            fullscreenLoading.value = false;
          }
        }).catch(function (error) {
          fullscreenLoading.value = false;
          console.log(error);
        });
      } else {
        fullscreenLoading.value = false;
        ElNotification({
          title: "失败",
          message: "未做任何修改！",
          type: "error",
          duration: 3000
        });
        return false;
      }
    };
    //删除图片之前
    const onBeforeRemove = (file, files, index) => {
      debugger;
      console.log(file, index);
      let uploadDeleteRO;
      let deleteIndex = null;
      let deleteUploadIndex = null;
      for (let z in demo[index].uploadedList) {
        if (file.uid === demo[index].uploadedList[z].uid) {
          console.log("第" + z + "个");
          deleteIndex = z;
          break;
        }
      }
      for (let z in uploadTableData[index].fileList) {
        if (file.uid === uploadTableData[index].fileList[z].uid) {
          console.log("第" + z + "个");
          deleteUploadIndex = z;
          break;
        }
      }
      if (file.uploadedId) {
        uploadDeleteRO = {
          uploadedId: file.uploadedId
        };
      } else {
        uploadDeleteRO = {
          uploadedName: file.uploadedRO.uploadedName
        };
      }
      fullscreenLoading.value = true;
      if (file.installationStatusId == 5 && file.activityInstallationUploadStatusId == 3) {
        const ignoreActivityInstallationUploadRO = {
          uploadedId: file.uploadedId
        };
        proxy.$api.repairRequest.ignoreActivityInstallationUpload(ignoreActivityInstallationUploadRO).then(function (response) {
          if (response.code === "200000") {
            if (response.result.dataCode === "202294") {
              ElNotification({
                title: "成功",
                message: response.result.promptMsg,
                type: "success",
                duration: 3000
              });
              if (demo[index].uploadedList && demo[index].uploadedList.length !== 0) {
                demo[index].uploadedList.splice(deleteIndex, 1);
              }
              if (uploadTableData[index].fileList && uploadTableData[index].fileList.length !== 0) {
                uploadTableData[index].fileList.splice(deleteUploadIndex, 1);
              }
            } else {
              ElNotification({
                title: "失败",
                message: response.result.promptMsg,
                type: "error",
                duration: 6000
              });
            }
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 3000
            });
            return false;
          }
          fullscreenLoading.value = false;
        }).catch(function (error) {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
          fullscreenLoading.value = false;
          return false;
        });
      } else {
        proxy.$api.repairRequest.deleteInstallationPhoto(uploadDeleteRO).then(function (response) {
          if (response.code === "200000") {
            if (response.result.dataCode === "202290") {
              ElNotification({
                title: "成功",
                message: response.result.promptMsg,
                type: "success",
                duration: 3000
              });
              if (demo[index].uploadedList && demo[index].uploadedList.length !== 0) {
                demo[index].uploadedList.splice(deleteIndex, 1);
              }
              if (uploadTableData[index].fileList && uploadTableData[index].fileList.length !== 0) {
                uploadTableData[index].fileList.splice(deleteUploadIndex, 1);
              }
            } else {
              ElNotification({
                title: "失败",
                message: response.result.promptMsg,
                type: "error",
                duration: 6000
              });
            }
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 3000
            });
            return false;
          }
          fullscreenLoading.value = false;
        }).catch(function (error) {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
          fullscreenLoading.value = false;
          return false;
        });
      }
    };
    // 删除图片
    const onRemove = (file, files) => {
      console.log(file, files);
    };
    //预览图片
    const handlePictureCardPreview = uploadFile => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    // 获取所有安装状态
    const getInstallationStatus = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getInstallationStatus(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //根据Id获取验收状态
    const getInstallName = id => {
      for (let i in installationStatusData) {
        if (installationStatusData[i].installationStatusId === id) {
          return installationStatusData[i].installationStatusNameZh;
        }
      }
    };
    //判断是否已验收
    const getDisabledDelete = uploadedInfo => {
      let isDisabled = true;
      if (uploadedInfo.installationStatusId != 5) {
        isDisabled = false;
      } else {
        if (uploadedInfo.uploaded != null && uploadedInfo.uploaded.length > 0) {
          for (let uploaded of uploadedInfo.uploaded) {
            if (uploaded.activityInstallationUploadStatusId == 3) {
              isDisabled = false;
            }
          }
        }
      }
      return isDisabled;
    };
    const getDisabledAdd = (x, index) => {
      if (uploadTableData[index].fileList != null && uploadTableData[index].fileList.length == 3) {
        return true;
      }
    };
    //获取活动信息
    const getActivity = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getActivity(activityId, true).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202154") {
              resolve(res.result.data);
            } else {
              ElNotification({
                title: "失败",
                message: res.result.promptMsg,
                type: "error",
                duration: 6000
              });
              resolve(null);
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 6000
            });
            resolve(null);
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //获取所有店铺
    const getStore = async Obj => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getStoreByActivityVendor(Obj, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const login = async () => {
      let params = {
        username: "temp_2024",
        password: "1",
        grant_type: "password"
      };
      return new Promise((resolve, reject) => {
        proxy.$api.userRequest.login(params).then(res => {
          sessionStorage.setItem("token", JSON.stringify(res));
          resolve(true);
        }).catch(error => {
          resolve(false);
        });
      });
    };
    const addInstallationPhoto = (index, uploadedRO) => {
      const approvedAddActivityInstallationUploadRO = {
        activityInstallationId: demo[index].activityInstallationId,
        uploaded: uploadedRO
      };
      proxy.$api.repairRequest.addInstallationPhoto(approvedAddActivityInstallationUploadRO).then(function (response) {
        if (response.code === "200000") {
          if (response.result.dataCode === "202292") {
            console.log(demo[index], uploadTableData[index]);
            ElNotification({
              title: "成功",
              message: response.result.promptMsg,
              type: "success",
              duration: 3000
            });
            fullscreenLoading.value = false;
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
          fullscreenLoading.value = false;
        }
      }).catch(function (error) {
        fullscreenLoading.value = false;
        console.log(error);
      });
    };
    const storeSelectChange = val => {
      activityInstallationROPage();
    };
    onMounted(async () => {
      if ((await login()) == false) {
        ElNotification({
          title: "失败",
          message: "临时用户验证失败",
          type: "error",
          duration: 3000
        });
        return;
      }
      // 获取所有安装状态
      let installationStatusList = await getInstallationStatus();
      if (installationStatusList == null) {
        return;
      }
      installationStatusData.push(...installationStatusList);
      //活动名字
      let getActivityName = await getActivity(route.params.activityId);
      ActivityName.value = getActivityName.activityName;
      let getVendorList = await getVendor();
      if (getVendorList == null) {
        return;
      }
      getVendorData.push(...getVendorList);

      // 供应商名字
      for (let i in getVendorData) {
        if (getVendorData[i].vendorId == route.params.vendorId) {
          vendorName.value = getVendorData[i].vendorName;
        }
      }
      let Obj = {
        activityId: activityId,
        vendorId: vendorId
      };
      let store = await getStore(Obj);
      if (store == null) {
        return;
      }
      for (let x in store) {
        let Obj = {
          value: store[x].storeCode,
          label: store[x].storeName
        };
        storeData.push(Obj);
      }
      getCurrentLocation(store);
    });

    // 获取当前位置的经纬度
    const getCurrentLocation = storeList => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const currentLatitude = position.coords.latitude;
          const currentLongitude = position.coords.longitude;

          // 在这里调用查找最近店铺的函数
          findNearestStore(currentLatitude, currentLongitude, storeList);
        }, function (error) {
          console.error("Error getting location:", error.message);
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    // 查找最近的店铺
    const findNearestStore = (currentLatitude, currentLongitude, storeList) => {
      // 计算最近的店铺
      let nearestStore = null;
      let minDistance = Number.MAX_VALUE;
      for (const store of storeList) {
        const distance = calculateDistance(currentLatitude, currentLongitude, store.latitude, store.longitude);
        if (distance < minDistance) {
          minDistance = distance;
          nearestStore = store;
        }
      }
      console.log("最近的店铺:", nearestStore);
      if (nearestStore != null) {
        storeCodeSelect.value = nearestStore.storeCode;
      } else {
        storeCodeSelect.value = storeData[0];
      }
      activityInstallationROPage();
    };

    // 计算两个经纬度之间的距离（简化，实际应用中可能需要更精确的算法）
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6371; // 地球半径（单位：公里）
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // 距离（单位：公里）
      return distance;
    };
    const deg2rad = degrees => {
      return degrees * (Math.PI / 180);
    };
    return {
      maxNum,
      value,
      demo,
      dialogImageUrl,
      dialogVisible,
      textarea1,
      uploadTable,
      uploadTableData,
      installationStatusData,
      getVendorData,
      fullscreenLoading,
      ActivityName,
      vendorName,
      onBeforeRemove,
      onRemove,
      handleStoreOnSuccess,
      handleBefore,
      sumbitStoreLocation,
      handleExceed,
      handlePictureCardPreview,
      getInstallName,
      getDisabledDelete,
      getDisabledAdd,
      storeCodeSelect,
      storeData,
      router,
      storeSelectChange
    };
  }
};