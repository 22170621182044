/**
 * userRequest模块接口列表
 */

import axios from "@/util/http"; // 导入http中创建的axios实例
import qs from "qs";
import cookies from "js-cookie";
import util from "@/util/util";

let Base64 = require("js-base64").Base64;

const organizationRequest = {
  getOrganization(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/organization/get`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getOrganizationBrand(retry) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/organization/getBrand`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  addOrganization(organizationAddRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/organization/add`, organizationAddRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  updateOrganization(organizationUpdateRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/organization/update`, organizationUpdateRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  deleteOrganization(organizationDeleteRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/organization/delete`, organizationDeleteRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getOrganizationUser(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/organization/getOrganizationUser`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default organizationRequest;
