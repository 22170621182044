import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, onMounted, onUnmounted, computed, getCurrentInstance } from "vue";
import { DownCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import SvgIcon from "../components/SvgIcon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import useClipboard from "vue-clipboard3";
import permission from "@/util/permission";
import html2canvas from "html2canvas";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import QrcodeVue from "qrcode.vue";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default defineComponent({
  name: "RepairManagement",
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon,
    QrcodeVue,
    DownCircleOutlined
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const {
      toClipboard
    } = useClipboard();
    const currentProtocol = window.location.protocol;
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;
    let currentUrl = currentProtocol + "//" + currentDomain;
    if (currentPort) {
      currentUrl += ":" + currentPort;
    }
    const jumpUrl = ref(currentUrl + "/repair-message");
    // const jumpUrl = ref("https://servicechannel.online/repair-message");
    // const jumpUrl = ref("http://192.168.60.248:8080/repair-message")
    // Loding开关
    const userLoading = ref(false);
    const wxCodeImage = ref(null);

    // 分页配置
    const paginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });
    const subscribeId = ref(null);
    const QRcodeBox = ref(null);
    const appointmentFormData = reactive({
      repairPerson: null,
      contactPerson: null,
      repairPersonContact: null,
      coordinatorContact: null,
      repairDate: null
    });
    const repairData = reactive([]);
    const repairEnd = ref(null);
    const repairStar = ref(null);
    const headerCellClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = xTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    const activeName = ref("first");
    var showPopover = null;
    const xTable = ref();
    const valuev2 = ref();
    const popoverVisible = ref(false);
    const QRcodeVisible = ref(false);
    const photoVisible = ref(false);
    const storeNameSelect = ref(null);
    const vendorNameSelect = ref(null);
    const activityNameSelect = ref(null);
    const QRcodeMsg = reactive({});
    const photosView = reactive([]);
    const appointmentDialogVisible = ref(false);
    const storeData = reactive([]);
    const vendorData = reactive([]);

    //感叹号弹框
    const showAppointmentInfo = (className, row, rowIndex) => {
      if (showPopover != null) {
        var popover = document.getElementsByClassName(showPopover.className);
        showPopover.row.popoverVisible = false;
        showPopover = null;
      }
      row.popoverVisible = true;
      showPopover = {
        className: className,
        row: row
      };
    };
    const initials = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];
    const optionsv2 = Array.from({
      length: 10000
    }).map((_, idx) => ({
      value: `Option ${idx + 1}`,
      label: `${initials[idx % 10]}${idx}`
    }));
    const handleTouchStartOutside = event => {
      if (showPopover != null) {
        var popover = document.getElementsByClassName(showPopover.className);
        if (popover[0] && isVisible(popover[0]) && !popover[0].contains(event.target)) {
          // 点击发生在指定div外部
          console.log("点击发生在div外部");
          showPopover.row.popoverVisible = false;
          showPopover = null;
        }
      }
    };
    // 监听分页Select变化
    const paginationSizeChange = () => {
      let data = {
        activityName: activityNameSelect.value,
        endDate: repairEnd.value,
        startDate: repairStar.value,
        storeName: storeNameSelect.value,
        vendorName: vendorNameSelect.value
      };
      getRepair(data);
    };
    // 监听分页当前页变化
    const paginationCurrentChange = () => {
      let data = {
        activityName: activityNameSelect.value,
        endDate: repairEnd.value,
        startDate: repairStar.value,
        storeName: storeNameSelect.value,
        vendorName: vendorNameSelect.value
      };
      getRepair(data);
    };
    // 获取报修列表
    const getRepair = (data, add) => {
      let repairSelectRO;
      if (data) {
        repairSelectRO = {
          data: data,
          pageIndex: paginationData.currentPage,
          pageSize: paginationData.pageSize
        };
      } else {
        repairSelectRO = {
          data: {},
          pageIndex: paginationData.currentPage,
          pageSize: paginationData.pageSize
        };
      }
      proxy.$api.repairRequest.getRepair(repairSelectRO).then(res => {
        if (res.code === "200000") {
          console.log(res);
          if (res.result.dataCode === "202281") {
            if (res.result.data.pageList != null) {
              if (add) {
                repairData.push(...res.result.data.pageList);
              } else {
                repairData.length = 0;
                repairData.push(...res.result.data.pageList);
              }
            } else {
              repairData.length = 0;
            }
            paginationData.totalSize = res.result.data.totalCount;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    const getCurrentDateTime = () => {
      let date = new Date();
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    //时间戳转时间
    const getDate = data => {
      let date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    };
    const handleClick = () => {};
    //确认预约
    const confirmSubscribe = () => {
      let Time = getCurrentDateTime();
      // console.log(Time);
      appointmentDialogVisible.value = false;
      let repairRO = {
        repairId: subscribeId.value,
        repairTime: Time,
        repairerMobile: appointmentFormData.repairPersonContact,
        repairerName: appointmentFormData.repairPerson,
        storeStaffMobile: appointmentFormData.coordinatorContact,
        storeStaffName: appointmentFormData.contactPerson
      };
      proxy.$api.repairRequest.repairSchedule(repairRO).then(res => {
        if (res.code === "200000") {
          console.log(res);
          if (res.result.dataCode === "202282") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "sucess",
              duration: 3000
            });
            let data = {
              activityName: activityNameSelect.value,
              endDate: repairEnd.value,
              startDate: repairStar.value,
              storeName: storeNameSelect.value,
              vendorName: vendorNameSelect.value
            };
            getRepair(data);
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.result.promptMsg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    //弹出二维码
    const openQrcode = row => {
      getRepairWxCode(row.repairId);
      // QRcodeVisible.value = true;
      QRcodeMsg.row = row;
      // console.log(jumpUrl.value + "?id=" + QRcodeMsg.row.repairId);
    };
    //点击照片显示轮播图
    const openPhotos = arr => {
      photoVisible.value = true;
      photosView.length = 0;
      photosView.push(...arr);
      console.log(photosView);
    };
    //点击查询
    const queryRepair = () => {
      let data = {
        activityName: activityNameSelect.value,
        endDate: repairEnd.value,
        startDate: repairStar.value,
        storeName: storeNameSelect.value,
        vendorName: vendorNameSelect.value
      };
      getRepair(data);
    };
    //获取所有店铺
    const getStore = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };

    // 预约信息复制
    const copyInfo = async row => {
      let text = "联系人：" + row.reporterDescription + "\n" + "电话：" + row.reporterMobile + "\n" + "维修日期：" + getDate(row.reporterTime);
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "成功",
          message: "复制到剪切板",
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };

    //二维码复制
    const copyQrcode = () => {
      html2canvas(QRcodeBox.value, {
        useCORS: true,
        // 【重要】开启跨域配置
        allowTaint: true // 允许跨域图片
      }).then(canvas => {
        canvas.toBlob(blob => {
          const data = [new ClipboardItem({
            [blob.type]: blob
          })];
          navigator.clipboard.write(data).then(() => {
            console.log("成功");
            ElNotification({
              title: "成功",
              message: "已复制到剪切板",
              type: "success",
              duration: 3000
            });
          }, () => {
            console.log("失败");
            ElNotification({
              title: "失败",
              message: "复制失败，此浏览器不支持",
              type: "error",
              duration: 3000
            });
          });
        }, "image/png");
      });
    };
    const focusChange = () => {
      console.log(123);
    };
    const userTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(paginationData.totalSize / paginationData.pageSize);
          console.log("-------------------" + paginationData.currentPage < totalCount);
          if (paginationData.currentPage < totalCount) {
            console.log("翻页");
            paginationData.currentPage++;
            let data = {
              cityName: Msg.insSelect.areaInput,
              searchKeyword: Msg.insValue,
              storeType: Msg.insSelect.storeType,
              vendorId: Msg.insSelect.vendorId,
              logisticsTypeId: Msg.insSelect.logisticsTypeId,
              state: Msg.insSelect.state
            };
            getLogisticsPageApi(data, paginationData.currentPage, paginationData.pageSize, "add");
          }
        }
      }
    };
    onMounted(async () => {
      window.addEventListener("touchstart", handleTouchStartOutside);
      window.addEventListener("click", handleTouchStartOutside);
      let windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (windowWidth <= 768) {
        paginationData.pageSize = 20;
      }
      getRepair();
      let store = await getStore();
      if (store == null) {
        return;
      }
      for (let x in store) {
        let Obj = {
          value: store[x].storeName,
          label: store[x].storeName
        };
        storeData.push(Obj);
      }
      let vendor = await getVendor();
      if (vendor == null) {
        return;
      }
      vendorData.push(...vendor);
    });
    onUnmounted(() => {
      window.removeEventListener("touchstart", handleTouchStartOutside);
      window.removeEventListener("click", handleTouchStartOutside);
    });
    const isVisible = element => {
      const style = window.getComputedStyle(element);
      return style.display !== "none" && style.visibility !== "hidden" && style.opacity !== "0";
    };
    const getRepairWxCode = id => {
      let repairWxCodeRO = {
        id: id
      };
      proxy.$api.wxRequest.getRepairWxCode(repairWxCodeRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "200000") {
            wxCodeImage.value = res.result.data;
            QRcodeVisible.value = true;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const rowClassName = row => {
      if (row.row.repairResultId === 4) {
        return 'borderLeftGreen';
      } else {
        return 'borderLeftRed';
      }
    };
    return {
      permission,
      paginationData,
      headerCellClickEvent,
      xTable,
      activeName,
      showAppointmentInfo,
      optionsv2,
      valuev2,
      popoverVisible,
      isVisible,
      appointmentDialogVisible,
      appointmentFormData,
      repairData,
      userLoading,
      subscribeId,
      QRcodeVisible,
      QRcodeMsg,
      photoVisible,
      photosView,
      modules: [Navigation, Pagination],
      repairStar,
      repairEnd,
      storeData,
      vendorData,
      storeNameSelect,
      vendorNameSelect,
      activityNameSelect,
      jumpUrl,
      paginationSizeChange,
      paginationCurrentChange,
      confirmSubscribe,
      openQrcode,
      handleClick,
      getDate,
      openPhotos,
      queryRepair,
      copyInfo,
      focusChange,
      userTableScroll,
      copyQrcode,
      QRcodeBox,
      wxCodeImage,
      getRepairWxCode,
      rowClassName
    };
  }
});