import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unshift.js";
import { useRoute, useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Lazy, Thumbs, Virtual, Pagination } from "swiper/modules";
import { genFileId } from "element-plus";
import ExDialog from "../components/ExDialog.vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import permission from "@/util/permission";
import util from "@/util/util";
import { ref, reactive, onBeforeMount, getCurrentInstance, nextTick, computed } from "vue";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: "StoreLocation",
  components: {
    Swiper,
    SwiperSlide,
    ExDialog
  },
  setup() {
    const router = useRoute();
    const $r = useRouter();
    const code = router.params.storeCode;
    const installationId = router.query.installationId;
    const activityId = router.query.activityId;
    const {
      proxy
    } = getCurrentInstance();
    const currentUploaded = reactive({
      activityVendors: null,
      uploadedInfo: null,
      originalUploadedInfo: null,
      index: null,
      installationStatusId: null
    });
    const storeLocationData = reactive({
      data: {},
      locations: [],
      locationsByStoreLocationId: {},
      props: {
        label: "locationName",
        children: "locations"
      },
      showType: 0,
      tab1index: 0,
      tab2index: 0,
      tab3index: 0,
      tab4index: 0,
      tab5index: 0,
      tab6index: 0,
      installationId: installationId,
      vendorTabIndex: 0
    });
    console.log(code);
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = swiper => {
      thumbsSwiper.value = swiper;
    };
    //获取店铺位置
    const getStoreLocation = async code => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreLocation(code, true).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202104") {
              //没有点位信息返回
              if (res.result.data.storeFloors.length === 0) {
                ElNotification({
                  title: "失败",
                  message: "暂无点位信息",
                  type: "error",
                  duration: 3000
                });
                $r.go(-1);
              } else {
                storeLocationData.data = res.result.data;
                headerSelect.value = true;
                //排序
                let customOrder = ["Primary Story", "RN", "BB", "Liftstyle", "Training", "Bra & Leggings", "YA", "JORDAN", "NBY", "JBY", "365"];
                for (let i = 0; i < storeLocationData.data.storeFloors.length; i++) {
                  let arr = storeLocationData.data.storeFloors[i].locations;
                  arr.sort((a, b) => {
                    const indexA = customOrder.indexOf(a.locationName);
                    const indexB = customOrder.indexOf(b.locationName);
                    return indexA - indexB; // 升序排序，如果需要降序，则改为 `return indexB - indexA;`
                  });
                }

                storeLocationData.locations.length = 0;
                if (Array.isArray(storeLocationData.data.storeFloors)) {
                  for (let storeFloor of storeLocationData.data.storeFloors) {
                    if (Array.isArray(storeFloor.locations)) {
                      for (let location1 of storeFloor.locations) {
                        if (Array.isArray(location1.locations)) {
                          for (let location2 of location1.locations) {
                            if (Array.isArray(location2.locations)) {
                              for (let location3 of location2.locations) {
                                if (Array.isArray(location3.locations)) {
                                  for (let location4 of location3.locations) {
                                    if (Array.isArray(location4.locations)) {
                                      for (let location5 of location4.locations) {
                                        const locationLabel = storeFloor.locationName + "-" + location1.locationName + "-" + location2.locationName + "-" + location3.locationName + "-" + location4.locationName;
                                        const locationObject = {
                                          locationLabel: locationLabel,
                                          supportType: location5.supportType,
                                          ice: location5.ice,
                                          gender: location5.gender,
                                          locationDescription: location5.locationDescription,
                                          quantity: location5.quantity,
                                          materialAndCraft: location5.materialAndCraft,
                                          sizeDescription: location5.sizeDescription,
                                          width: location5.width,
                                          height: location5.height,
                                          storeLocationId: location5.storeLocationId,
                                          installationStatusId: location5.installationStatusId,
                                          originalUploaded: [],
                                          uploaded: [],
                                          activityVendors: location5.activityVendors,
                                          vendorTabIndex: 0
                                        };
                                        storeLocationData.locations.push(locationObject);
                                        if (Array.isArray(location5.uploadedInfo)) {
                                          for (let info of location5.uploadedInfo) {
                                            locationObject.uploaded.push(info);
                                          }
                                        }
                                        if (activityId) {
                                          for (let vendor of locationObject.activityVendors) {
                                            const filteredVendorArray = locationObject.uploaded.filter(function (element) {
                                              return element.vendorId === vendor.vendorId;
                                            });
                                            if (filteredVendorArray.length == 0) {
                                              vendor.isEmpty = true;
                                            }
                                          }
                                          locationObject.originalUploaded = locationObject.uploaded;
                                          vendorTabBarChange(locationObject, 0);
                                        }
                                        storeLocationData.locationsByStoreLocationId[location5.storeLocationId] = locationObject;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              ElNotification({
                title: "失败",
                message: res.result.promptMsg,
                type: "error",
                duration: 3000
              });
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 3000
            });
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 重置所有层级为0
    const resetIndex = type => {
      // if (type === 0) {
      //   headerSelect.value = true;
      //   currentUploaded.uploadedInfo = storeLocationData.header;
      //   currentUploaded.index = 0;
      //   debugger;
      //   return;
      // }
      if (type === 1) {
        headerSelect.value = false;
        storeLocationData.tab2index = storeLocationData.tab3index = storeLocationData.tab4index = storeLocationData.tab5index = storeLocationData.tab6index = 0;
      }
      if (type === 2) {
        storeLocationData.tab3index = storeLocationData.tab4index = storeLocationData.tab5index = storeLocationData.tab6index = 0;
      }
      if (type === 3) {
        storeLocationData.tab4index = storeLocationData.tab5index = storeLocationData.tab6index = 0;
      }
      if (type === 4) {
        storeLocationData.tab5index = storeLocationData.tab6index = 0;
      }
      if (type === 5) {
        storeLocationData.tab6index = 0;
      }
      let info = storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locations[storeLocationData.tab4index].locations[storeLocationData.tab5index].locations[storeLocationData.tab6index];
      currentUploaded.uploadedInfo = info.uploadedInfo;
      currentUploaded.installationStatusId = info.installationStatusId;
      currentUploaded.index = 0;
      if (activityId) {
        currentUploaded.originalUploadedInfo = info.uploadedInfo;
        currentUploaded.activityVendors = info.activityVendors;
        if (currentUploaded.activityVendors) {
          for (let vendor of currentUploaded.activityVendors) {
            const filteredVendorArray = currentUploaded.uploadedInfo.filter(function (element) {
              return element.vendorId === vendor.vendorId;
            });
            if (filteredVendorArray.length == 0) {
              vendor.isEmpty = true;
            }
          }
          vendorTabChange(storeLocationData.vendorTabIndex);
        }
      }
    };
    const vendorTabChange = index => {
      let vendor = currentUploaded.activityVendors[index];
      currentUploaded.uploadedInfo = currentUploaded.originalUploadedInfo.filter(function (element) {
        return element.vendorId === vendor.vendorId;
      });
    };

    // 当前层级信息
    function getInfo() {
      let info = storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locations[storeLocationData.tab4index].locations[storeLocationData.tab5index].locations[storeLocationData.tab6index];
      return info;
    }
    // 当前层级名字
    function getInfoName() {
      if (headerSelect.value == true) {
        currentUploaded.uploadedInfo = null;
        return "门头";
      }
      let info = storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locations[storeLocationData.tab4index].locations[storeLocationData.tab5index].locations[storeLocationData.tab6index].locationName;
      if (!info) {
        info = storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locations[storeLocationData.tab4index].locations[storeLocationData.tab5index].locationName;
      }
      return info;
    }
    onBeforeMount(async () => {
      const storeLocationRO = {
        storeCode: code,
        installationId: installationId,
        activityId: activityId
      };
      await getStoreLocation(storeLocationRO);
    });
    const goRepair = msg => {
      let storeLocationName = storeLocationData.data.storeFloors[storeLocationData.tab1index].locationName + "-" + storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locationName + "-" + storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locationName + "-" + storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locations[storeLocationData.tab4index].locationName + "-" + storeLocationData.data.storeFloors[storeLocationData.tab1index].locations[storeLocationData.tab2index].locations[storeLocationData.tab3index].locations[storeLocationData.tab4index].locations[storeLocationData.tab5index].locationName;
      console.log(storeLocationName);
      $r.push({
        name: "report-repair",
        params: {
          storeLocationId: msg.storeLocationId,
          storeCode: storeLocationData.data.storeCode,
          storeName: storeLocationData.data.storeName,
          storeLocationName: storeLocationName
        }
      });
    };
    const headerSelect = ref(true);
    const uploadedSlide = ref(null);
    const onSlideChange = swiper => {
      currentUploaded.index = swiper.activeIndex;
    };
    const onSwiper = swiper => {};
    const approvedUploadedAll = () => {
      ElMessageBox.confirm("是否要审批通过所有上传照片", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let activityInstallationApprovedRO = {
          installationId: installationId
        };
        approvedActivityInstallationUploadedAllRequest(activityInstallationApprovedRO);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消审批"
        });
      });
    };
    const approvedUploaded = uploaded => {
      ElMessageBox.confirm("是否要审批通过上传照片", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        approvedActivityInstallationUploadedRequest(uploaded);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消审批"
        });
      });
    };
    const nullifiedUploaded = uploaded => {
      ElMessageBox.confirm("是否要作废上传照片", "询问", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        nullifiedActivityInstallationUploadedRequest(uploaded);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消审批"
        });
      });
    };
    const approvedActivityInstallationUploadedAllRequest = async activityInstallationApprovedRO => {
      proxy.$api.activityRequest.approvedActivityInstallationUploadedAll(activityInstallationApprovedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202173") {
            if (Array.isArray(storeLocationData.data.storeFloors)) {
              for (let storeFloor of storeLocationData.data.storeFloors) {
                if (Array.isArray(storeFloor.locations)) {
                  for (let location1 of storeFloor.locations) {
                    if (Array.isArray(location1.locations)) {
                      for (let location2 of location1.locations) {
                        if (Array.isArray(location2.locations)) {
                          for (let location3 of location2.locations) {
                            if (Array.isArray(location3.locations)) {
                              for (let location4 of location3.locations) {
                                if (Array.isArray(location4.locations)) {
                                  for (let location5 of location4.locations) {
                                    if (Array.isArray(location5.uploadedInfo)) {
                                      for (let info of location5.uploadedInfo) {
                                        info.activityInstallationUploadStatusId = 2;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            storeLocationData.data.storeLocationStatusId = 2;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const approvedActivityInstallationUploadedRequest = async uploaded => {
      let activityInstallationUploadedApprovedRO = {
        activityInstallationToUploadId: uploaded.activityInstallationToUploadId
      };
      proxy.$api.activityRequest.approvedActivityInstallationUploaded(activityInstallationUploadedApprovedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202173") {
            uploaded.activityInstallationUploadStatusId = 2;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const nullifiedActivityInstallationUploadedRequest = async uploaded => {
      let activityInstallationUploadedNullifieRO = {
        activityInstallationToUploadId: uploaded.activityInstallationToUploadId
      };
      proxy.$api.activityRequest.nullifiedActivityInstallationUploaded(activityInstallationUploadedNullifieRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202184") {
            uploaded.activityInstallationUploadStatusId = 3;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "错误",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const headerTabClick = () => {
      headerSelect.value = true;
    };
    const uploadActivityPhotoData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      file: {
        url: null
      }
    });
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    const uploadActivityPhoto = ref(null);
    const handleActivityPhotoBeforeUpload = async (file, storeLocationId) => {
      uploadActivityPhotoData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadActivityPhotoData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadActivityPhotoData.signatureData.dir + fullName;
      const uploadData = {
        name: file.name,
        key: keyValue,
        policy: uploadActivityPhotoData.signatureData.policy,
        OSSAccessKeyId: uploadActivityPhotoData.signatureData.accessId,
        signature: uploadActivityPhotoData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid,
        param: {
          storeLocationId: storeLocationId
        }
      };
      uploadActivityPhotoData.data = uploadData;
      file.uploadData = uploadData;
    };
    const handleActivityPhotoExceed = files => {
      debugger;
      // uploadActivityPhoto.value.clearFiles();
      // const file = files[0];
      // file.uid = genFileId();
      // uploadActivityPhoto.value.handleStart(file);
      // uploadActivityPhoto.value.submit();
    };

    const handleActivityPhotoOnSuccess = async (response, file, files) => {
      debugger;
      if (file.status === "success") {
        let uploadData = file.raw.uploadData;
        let activityInstallationPhotoUploadRO = {
          installationId: storeLocationData.installationId,
          storeLocationId: uploadData.param.storeLocationId,
          uploadedRO: {
            fileName: uploadData.name,
            uploadedName: uploadData.key
          }
        };
        uploadActivityInstallationPhotoRequest(activityInstallationPhotoUploadRO);
      }
    };
    const uploadActivityInstallationPhotoRequest = async activityInstallationPhotoUploadRO => {
      proxy.$api.repairRequest.uploadActivityInstallationPhoto(activityInstallationPhotoUploadRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202266") {
            let uploaded = res.result.data;
            storeLocationData.locationsByStoreLocationId[uploaded.storeLocationId].uploaded.unshift(uploaded);
            if (Array.isArray(storeLocationData.data.storeFloors)) {
              for (let storeFloor of storeLocationData.data.storeFloors) {
                if (Array.isArray(storeFloor.locations)) {
                  for (let location1 of storeFloor.locations) {
                    if (Array.isArray(location1.locations)) {
                      for (let location2 of location1.locations) {
                        if (Array.isArray(location2.locations)) {
                          for (let location3 of location2.locations) {
                            if (Array.isArray(location3.locations)) {
                              for (let location4 of location3.locations) {
                                if (Array.isArray(location4.locations)) {
                                  for (let location5 of location4.locations) {
                                    if (location5.storeLocationId == uploaded.storeLocationId) {
                                      if (!Array.isArray(location5.uploadedInfo)) {
                                        location5.uploadedInfo = [];
                                      }
                                      location5.uploadedInfo.unshift(uploaded);
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const vendorTabBarChange = (locationObject, index) => {
      let vendor = locationObject.activityVendors[index];
      locationObject.uploaded = locationObject.originalUploaded.filter(function (element) {
        return element.vendorId === vendor.vendorId;
      });
    };
    return {
      activityId,
      currentUploaded,
      permission,
      storeLocationData,
      Thumbs,
      Lazy,
      Navigation,
      Pagination,
      thumbsSwiper,
      setThumbsSwiper,
      Virtual,
      resetIndex,
      getInfo,
      goRepair,
      getInfoName,
      headerSelect,
      uploadedSlide,
      onSlideChange,
      onSwiper,
      approvedUploadedAll,
      approvedUploaded,
      nullifiedUploaded,
      headerTabClick,
      uploadActivityPhotoData,
      uploadActivityPhoto,
      handleActivityPhotoBeforeUpload,
      handleActivityPhotoExceed,
      handleActivityPhotoOnSuccess,
      vendorTabChange,
      vendorTabBarChange
    };
  }
};