import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0afd3cd2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  style: {
    "width": "90%",
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_3 = ["href"];
const _hoisted_4 = {
  class: "file-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_svg_icon, {
    iconName: $setup.fileType,
    iconColor: '#6c6c6c',
    iconSize: '22px',
    style: _normalizeStyle({
      margin: $props.iconMargin
    })
  }, null, 8, ["iconName", "style"]), _createElementVNode("div", {
    class: "content",
    style: _normalizeStyle({
      marginRight: $props.contentMarginRight
    })
  }, [_createElementVNode("a", {
    href: $setup.enUrl,
    target: "_blank"
  }, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.fileName), 1)], 8, _hoisted_3)], 4)]), $props.close == true ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    class: "delete-button button-default noborder small",
    circle: "",
    onClick: $setup.removeDiv
  }, {
    default: _withCtx(() => [_createVNode(_component_svg_icon, {
      iconName: "folderCardDelete",
      iconSize: "18px"
    })]),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true)]);
}