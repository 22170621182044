import cookies from "js-cookie";
import router from "../router";
import { ElNotification } from "element-plus";
const util = {
  getToken() {
    let token = sessionStorage.getItem("token");
    if (token == null) {
      token = cookies.get("token");
    }
    try {
      var tokenJson = JSON.parse(token);
    } catch (error) {
      var redirect = window.location.href;
      var query = {};
      if (redirect) {
        redirect = encodeURIComponent(redirect);
        query.redirect = redirect;
      }
      ElNotification({
        message: "请先登录",
        type: "error",
        duration: 3000,
      });
      router.replace({
        path: "/",
        query: query,
      });
      return {};
    }

    return tokenJson;
  },
  getUserInfo() {
    let userInfo = JSON.parse(
      window.localStorage.getItem("pnp_user_info" || "{}")
    );

    return userInfo;
  },
  checkType(any) {
    return Object.prototype.toString.call(any).slice(8, -1);
  },
  clone(any) {
    if (this.checkType(any) === "Object") {
      // 拷贝对象
      let o = {};
      for (let key in any) {
        o[key] = this.clone(any[key]);
      }
      return o;
    } else if (this.checkType(any) === "Array") {
      // 拷贝数组
      var arr = [];
      for (let i = 0, leng = any.length; i < leng; i++) {
        arr[i] = this.clone(any[i]);
      }
      return arr;
    } else if (this.checkType(any) === "Function") {
      // 拷贝函数
      return new Function("return " + any.toString()).call(this);
    } else if (this.checkType(any) === "Date") {
      // 拷贝日期
      return new Date(any.valueOf());
    } else if (this.checkType(any) === "RegExp") {
      // 拷贝正则
      return new RegExp(any);
    } else if (this.checkType(any) === "Map") {
      // 拷贝Map 集合
      let m = new Map();
      any.forEach((v, k) => {
        m.set(k, this.clone(v));
      });
      return m;
    } else if (this.checkType(any) === "Set") {
      // 拷贝Set 集合
      let s = new Set();
      for (let val of any.values()) {
        s.add(this.clone(val));
      }
      return s;
    }
    return any;
  },
  propertyClone(sourceData, destinationData) {
    for (let key in destinationData) {
      if (sourceData[key] !== undefined) {
        destinationData[key] = sourceData[key];
      }
    }
  },
  getSuffix(filename) {
    let pos = filename.lastIndexOf(".");
    let suffix = "";
    if (pos != -1) {
      suffix = filename.substring(pos);
    }
    return suffix;
  },
  debounce(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  },
  parseParams(data) {
    try {
      var tempArr = [];
      for (var i in data) {
        var key = encodeURIComponent(i);
        var value = encodeURIComponent(data[i]);
        tempArr.push(key + "=" + value);
      }
      var urlParamsStr = tempArr.join("&");
      return urlParamsStr;
    } catch (err) {
      return "";
    }
  },
  removeArrayById(prop, obj, id) {
    if (Array.isArray(obj)) {
      for (let i = obj.length - 1; i >= 0; i--) {
        if (obj[i][prop] === id) {
          obj.splice(i, 1);
          break;
        }
      }
    }
  },
  resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          context.drawImage(image, 0, 0, width, height);
          canvas.toBlob(function (blob) {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          }, file.type);
        };
        image.onerror = function (error) {
          reject(error);
        };
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  },
};

export default util;
