import "core-js/modules/es.array.push.js";
import moment from "moment";
import { get } from "xe-utils";
export default {
  name: "MyCalendar",
  data() {
    return {
      firstYearMonth: "",
      secondYearMonth: "",
      currentDate: "",
      startDate: "",
      endDate: "",
      daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      firstDaysArray: [],
      secondDaysArray: [],
      calendarPopoverVisible: false
    };
  },
  methods: {
    convert2CnYear(date) {
      return moment(date).format("YYYY年MM月");
    },
    changeFirstdate(date, num) {
      let datastring = moment(date).add(num, "M").format("YYYY-MM");
      let year = moment(datastring).year();
      let month = moment(datastring).month() + 1;
      this.firstYearMonth = moment(datastring).format("YYYY-MM");
      this.firstDaysArray = this.createMonthArray(year, month);
    },
    changeSeconddate(date, num) {
      debugger;
      let datastring = moment(date).add(num, "M").format("YYYY-MM");
      let year = moment(datastring).year();
      let month = moment(datastring).month() + 1;
      this.secondYearMonth = moment(datastring).format("YYYY-MM");
      this.secondDaysArray = this.createMonthArray(year, month);
    },
    isChoiceDay(date) {
      // console.log("startdate=",this.startDate,"enddate=",this.endDate);
      if (this.startDate && this.startDate != "") {
        let result = moment(this.startDate).isSame(date);
        if (result) {
          return result;
        }
      }
      if (this.startDate && this.startDate != "" && this.endDate && this.endDate != "") {
        let result = moment(date).isBetween(this.startDate, this.endDate, null, "[]");
        // console.log(result,date);
        return result;
      }
      // console.log("other result",date);
      return false;
    },
    isToday(date) {
      return moment().isSame(date, "day");
    },
    setDayId(firstYearMonth, day) {
      if (day == 0 || day == "") {
        return "";
      }
      let result = firstYearMonth + "-" + (day > 9 ? day : "0" + day);
      // console.log("setDayId",result);
      return result;
    },
    checkDate() {
      if (this.startDate != "" && this.endDate != "") {
        if (moment(this.startDate).diff(moment(this.endDate)) > 0) {
          let tmp = this.endDate;
          this.endDate = this.startDate;
          this.startDate = tmp;
        }
      }
    },
    setDate(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
    },
    clickDate(event) {
      if (event.target.id) {
        if (this.startDate == "") {
          this.startDate = event.target.id;
        } else {
          this.endDate = event.target.id;
          this.checkDate();
          // console.log("@-----------------")
          this.sendDataToParent();
        }
      }
      // alert(event.target.id);
    },

    sendDataToParent() {
      // 子组件向父组件发送数据
      let obj = {};
      obj.startDate = this.startDate;
      obj.endDate = this.endDate;
      this.$emit("sendDate", obj);
    },
    convert2DimArray(arr, size) {
      // arr是一维数组 size是二维数组包含几条数据
      let arr2 = [];
      for (let i = 0; i < arr.length; i = i + size) {
        arr2.push(arr.slice(i, i + size));
      }
      let length = arr2.length;
      let colomn = arr2[length - 1].length;
      if (colomn < size) {
        let needAdd = size - colomn;
        for (let i = 0; i < needAdd; i++) {
          arr2[length - 1].push("");
        }
      }
      return arr2; // 新的二维数组
    },

    createMonthArray(year, month) {
      let totalDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
      let dayOfMonth = moment(`${year}-${month}`, "YYYY-MM").day();
      // console.log(dayOfMonth);
      let dayArray = [];
      for (let i = 0; i < dayOfMonth; i++) {
        dayArray.push("");
      }
      for (let i = 0; i < totalDays; i++) {
        dayArray.push(i + 1);
      }
      return this.convert2DimArray(dayArray, 7);
      // console.log("dayArray=",dayArray);
      // moment(`{year}-{month}`, "YYYY-MM").daysInMonth()
    },

    getWeek(week) {
      if (!week) {
        return "";
      }
      const today = moment(week);
      const dayOfWeek = today.format("ddd");
      return "(" + dayOfWeek + ")";
    },
    getBlack(msg) {
      if (msg && msg === this.startDate) {
        return true;
      } else if (msg && msg === this.endDate) {
        return true;
      }
      return false;
    },
    getBlack2(msg) {
      if (msg && msg === this.startDate) {
        return true;
      }
      return false;
    }
  },
  created() {
    moment.locale("zh-cn");
    //     var dateString = "2023-11-15";

    // // 使用 moment 解析日期字符串
    // var dateObject = moment(dateString);
    // var formattedMonth = dateObject.format("MM");

    // console.log("两位数月份: " + formattedMonth);

    this.currentDate = moment().format("YYYY-MM-DD");
    this.firstYearMonth = moment().format("YYYY-MM");
    this.secondYearMonth = moment().add(1, "M").format("YYYY-MM");
    // this.endDate = moment().add(1,'M').format("YYYY-MM-DD");

    let firstYear = moment(this.firstYearMonth).year();
    let firstMonth = moment(this.firstYearMonth).month() + 1;
    this.firstDaysArray = this.createMonthArray(firstYear, firstMonth);
    let secondYear = moment(this.secondYearMonth).year();
    let secondMonth = moment(this.secondYearMonth).month() + 1;
    this.firstDaysArray = this.createMonthArray(firstYear, firstMonth);
    this.secondDaysArray = this.createMonthArray(secondYear, secondMonth);
  },
  computed: {},
  props: {
    msg: String
  }
};