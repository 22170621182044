import { translate } from "element-plus";
export default {
  name: "SvgIcon",
  props: {
    //图标名称
    iconName: {
      type: String,
      default: ""
    },
    iconColor: String,
    // 图标颜色
    iconSize: String,
    // 图标大小
    iconBackgroundColor: String
  }
};