import { defineComponent, ref, onMounted, onUnmounted } from "vue";
export default defineComponent({
  props: {
    sign: {
      type: Number,
      default: 0
    }
  },
  emits: {
    "pane-size-change": null
  },
  setup(props, context) {
    const pane = ref({});
    const size = ref({
      width: 0,
      height: 0
    });
    const resizeObserver = new ResizeObserver(entries => {
      if (pane.value != null) {
        var paneClientRect = pane.value.getBoundingClientRect();
        if (size.value.width != paneClientRect.width || size.value.height != paneClientRect.height) {
          size.value.width = paneClientRect.width;
          size.value.height = paneClientRect.height;
          context.emit("pane-size-change", {
            width: size.value.width,
            height: size.value.height
          }, props.sign);
        }
      }
    });
    onMounted(() => {
      if (pane.value != null) {
        resizeObserver.observe(pane.value);
      }
    });
    onUnmounted(() => {
      if (pane.value != null) {
        resizeObserver.unobserve(pane.value);
      }
    });
    return {
      pane,
      size
    };
  },
  methods() {}
});