import { defineComponent, onMounted } from "vue";
import { ElConfigProvider } from "element-plus";
import elzhCN from "element-plus/dist/locale/zh-cn.mjs";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default defineComponent({
  components: {
    ElConfigProvider
  },
  setup() {
    //接管ResizeObserver,防止ResizeObserver loop completed with undelivered notifications.
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    const themeConfig = {
      components: {
        Select: {
          fontSizeIcon: 24,
          controlHeight: 38
        },
        Input: {
          controlHeight: 38
        },
        InputNumber: {
          controlHeight: 38
        },
        DatePicker: {
          fontSizeIcon: 24,
          controlHeight: 38
        },
        Form: {
          marginLG: 30
        },
        Button: {
          borderRadius: 12
        },
        Divider: {
          marginLG: 10
        }
      },
      token: {
        borderRadius: 0,
        fontSize: 12,
        colorBorder: "#818181"
      }
    };
    return {
      locale: zhCN,
      elzhCN,
      themeConfig
    };
  }
});