import "core-js/modules/es.array.push.js";
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { Search, Plus } from "@element-plus/icons-vue";
import ExDialog from "../components/ExDialog.vue";
import { genFileId } from "element-plus";
import util from "@/util/util";
import permission from "@/util/permission";


import { ElNotification, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message/style/css';
// import { ElMessage , ClickOutside , vClickOutside } from "element-plus"
export default {
  name: "VendorManage",
  components: {
    Search,
    ExDialog,
    Plus
  },
  setup() {
    const windowRef = ref(window);
    const xTable = ref(null);
    const {
      proxy
    } = getCurrentInstance();
    var currentRow = null;
    // 搜索框input信息
    const searchInput = ref("");
    // 添加供应商的图片路径
    const imageUrl = ref("");
    // 修改供应商的图片路径
    const editImageUrl = ref("");
    // 供应商列表
    const vendorData = reactive({
      data: []
    });
    const renderTable = ref(false);
    // 供应商分类信息
    const VendorTypeData = reactive([]);
    // 添加供应商弹框开关
    const addSupplier = ref(false);
    // 修改供应商弹框开关
    const editSupplier = ref(false);
    // 点击...的弹框
    const popoverVisible = ref(false);
    // Loding开关
    const userLoading = ref(false);
    // 添加供应商的input信息
    const addVendorInfo = reactive({
      vendorNameInput: "",
      vendorLogoInput: "",
      contactAddressInput: "",
      contactNameInput: "",
      contactMobileInput: "",
      contactEmailInput: "",
      contactTypeInput: ""
    });
    // 修改供应商的input信息
    const editVendorInfo = reactive({
      vendorNameInput: "",
      vendorLogoInput: "",
      contactAddressInput: "",
      contactNameInput: "",
      contactMobileInput: "",
      contactEmailInput: "",
      contactTypeInput: "",
      vendorId: ""
    });
    // 分页配置
    const paginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });
    // 添加供应商弹框-取消点击事件
    const supplierCancel = () => {
      addVendorInfo.vendorNameInput = "";
      addVendorInfo.vendorLogoInput = "";
      addVendorInfo.contactAddressInput = "";
      addVendorInfo.contactNameInput = "";
      addVendorInfo.contactMobileInput = "";
      addVendorInfo.contactEmailInput = "";
      addVendorInfo.contactTypeInput = "";
      imageUrl.value = "";
      addSupplier.value = false;
    };
    // 修改供应商弹框-取消点击事件
    const editCancel = () => {
      editVendorInfo.vendorLogoInput = "";
      editVendorInfo.contactAddressInput = "";
      editVendorInfo.contactNameInput = "";
      editVendorInfo.contactMobileInput = "";
      editVendorInfo.contactEmailInput = "";
      editImageUrl.value = "";
      editSupplier.value = false;
    };
    // 获取供应商列表
    const getVendorPageApi = async (searchKeyword, pageIndex, pageSize, add) => {
      userLoading.value = true;
      let vendorPageROPageInfo = {
        data: {
          searchKeyword: searchKeyword
        },
        pageIndex: pageIndex,
        pageSize: pageSize
      };
      proxy.$api.vendorRequest.getVendorPage(vendorPageROPageInfo).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202150") {
            let vendorList = res.result.data.pageList;
            if (vendorList != null) {
              if (add) {
                vendorData.data.push(...vendorList);
              } else {
                vendorData.data.length = 0;
                vendorData.data.push(...vendorList);
              }
            } else {
              vendorData.data.length = 0;
            }
            paginationData.totalSize = res.result.data.totalCount;
            console.log("获取成功");
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    // 获取供应商分类列表
    const getVendorType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorType(true).then(res => {
          resolve(res.result.data);
          VendorTypeData.length = 0;
          let VendorType = res.result.data;
          VendorTypeData.push(...VendorType);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 添加供应商的请求
    const vendorAdd = async vendorAddRO => {
      console.log(vendorAddRO);
      userLoading.value = true;
      proxy.$api.vendorRequest.addVendor(vendorAddRO).then(res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202152") {
            vendorData.data.push(res.result.data);
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            addSupplier.value = false;
            supplierCancel();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    // 修改供应商的请求
    const vendorUpdate = async vendorUpdateRO => {
      console.log(vendorUpdateRO);
      userLoading.value = true;
      proxy.$api.vendorRequest.updateVendor(vendorUpdateRO).then(res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202153") {
            util.propertyClone(res.result.data, currentRow);
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            editSupplier.value = false;
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    // 删除供应商的请求
    const deleteVendorApi = async vendorId => {
      userLoading.value = true;
      let vendorDeleteRO = {
        vendorId: vendorId
      };
      proxy.$api.vendorRequest.deleteVendor(vendorDeleteRO).then(res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202154") {
            util.removeArrayById("vendorId", vendorData.data, currentRow.vendorId);
            console.log("删除成功");
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
            getVendorPageApi("", paginationData.currentPage, paginationData.pageSize);
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    // 监听分页Select变化
    const paginationSizeChange = () => {
      getVendorPageApi("", paginationData.currentPage, paginationData.pageSize);
    };
    // 监听分页当前页变化
    const paginationCurrentChange = () => {
      getVendorPageApi("", paginationData.currentPage, paginationData.pageSize);
    };
    // 搜索点击事件
    const searchClick = () => {
      getVendorPageApi(searchInput.value, paginationData.currentPage, paginationData.pageSize);
    };
    // 添加供应商-确认bytton的点击事件
    const addSupplierApi = () => {
      if (!addVendorInfo.vendorNameInput) {
        ElNotification({
          title: "失败",
          message: "供应商名称不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!addVendorInfo.contactAddressInput) {
        ElNotification({
          title: "失败",
          message: "供应商地址不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!addVendorInfo.contactNameInput) {
        ElNotification({
          title: "失败",
          message: "联系人不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!addVendorInfo.contactMobileInput) {
        ElNotification({
          title: "失败",
          message: "联系方式不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!addVendorInfo.contactEmailInput) {
        ElNotification({
          title: "失败",
          message: "邮箱不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!addVendorInfo.contactTypeInput) {
        ElNotification({
          title: "失败",
          message: "请选择供应商类型",
          type: "error",
          duration: 3000
        });
        return false;
      }
      let vendorAddRO = {
        address: addVendorInfo.contactAddressInput,
        contactEmail: addVendorInfo.contactEmailInput,
        contactMobile: addVendorInfo.contactMobileInput,
        contactName: addVendorInfo.contactNameInput,
        vendorLogo: imageUrl.value,
        vendorName: addVendorInfo.vendorNameInput,
        vendorTypeId: addVendorInfo.contactTypeInput
      };
      vendorAdd(vendorAddRO);
    };
    // 修改供应商-确认bytton的点击事件
    const editSupplierApi = () => {
      if (!editVendorInfo.contactNameInput) {
        ElNotification({
          title: "失败",
          message: "联系人不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!editVendorInfo.contactAddressInput) {
        ElNotification({
          title: "失败",
          message: "供应商地址不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!editVendorInfo.contactMobileInput) {
        ElNotification({
          title: "失败",
          message: "联系方式不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      if (!editVendorInfo.contactEmailInput) {
        ElNotification({
          title: "失败",
          message: "邮箱不可为空",
          type: "error",
          duration: 3000
        });
        return false;
      }
      let vendorUpdateRO = {
        address: editVendorInfo.contactAddressInput,
        contactEmail: editVendorInfo.contactEmailInput,
        contactMobile: editVendorInfo.contactMobileInput,
        contactName: editVendorInfo.contactNameInput,
        vendorId: editVendorInfo.vendorId,
        vendorLogo: editImageUrl.value,
        vendorName: editVendorInfo.vendorNameInput,
        vendorTypeId: editVendorInfo.contactTypeInput
      };
      vendorUpdate(vendorUpdateRO);
    };
    // 上传头像之前的钩子
    const beforeAvatarUpload = file => {
      if (file.type !== "image/jpeg") {
        ElMessage.error("Avatar picture must be JPG format!");
        return false;
      } else if (file.size / 1024 / 1024 > 2) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
        return false;
      }
      const reader = new FileReader();
      reader.onload = e => {
        imageUrl.value = e.target.result; // 这里就是图片的 Base64 编码
      };

      reader.readAsDataURL(file);
      return false;
    };
    // 上传头像之前的钩子
    const beforeAvatarUploadEdit = file => {
      if (file.type !== "image/jpeg") {
        ElMessage.error("Avatar picture must be JPG format!");
        return false;
      } else if (file.size / 1024 / 1024 > 2) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
        return false;
      }
      const reader = new FileReader();
      reader.onload = e => {
        editImageUrl.value = e.target.result; // 这里就是图片的 Base64 编码
      };

      reader.readAsDataURL(file);
      return false;
    };
    // 修改的点击事件
    const editVendor = row => {
      currentRow = row;
      debugger;
      console.log(row);
      editSupplier.value = true;
      editVendorInfo.vendorNameInput = row.vendorName;
      editVendorInfo.contactTypeInput = row.vendorTypeId;
      editVendorInfo.vendorId = row.vendorId;
      editVendorInfo.contactNameInput = row.contactName;
      editVendorInfo.contactAddressInput = row.address;
      editVendorInfo.contactMobileInput = row.contactMobile;
      editVendorInfo.contactEmailInput = row.contactEmail;
      editImageUrl.value = row.vendorLogo;
    };
    // 删除的点击事件
    const deleteVendor = row => {
      currentRow = row;
      deleteVendorApi(row.vendorId);
    };
    // 根据供应商id获取供应商名字
    const getVendorName = id => {
      for (let i = 0; i < VendorTypeData.length; i++) {
        if (VendorTypeData[i].vendorTypeId === id) {
          return VendorTypeData[i].vendorTypeNameZh;
        }
      }
    };
    const vendorLoad = () => {
      userLoading.value = true;
      getVendorPageApi("", paginationData.currentPage, paginationData.pageSize, "add");
    };
    onMounted(async () => {
      getVendorPageApi("", paginationData.currentPage, paginationData.pageSize);
      getVendorType();
    });
    const userTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(paginationData.totalSize / paginationData.pageSize);
          if (paginationData.currentPage < totalCount) {
            console.log("翻页");
            paginationData.currentPage++;
            vendorLoad();
          }
        }
      }
    };
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    const uploadVendor = ref(null);
    const uploadVendorPopoverVisible = ref(false);
    const uploadVendorData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const openUploadVendorPopover = () => {
      uploadVendor.value.clearFiles();
      uploadVendorPopoverVisible.value = true;
    };
    const handleVendorBeforeUpload = async file => {
      debugger;
      uploadVendorData.signatureData = await getSignature("excel/temp");
      let filename = file.name;
      let fullName = uploadVendorData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadVendorData.signatureData.dir + fullName;
      uploadVendorData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadVendorData.signatureData.policy,
        OSSAccessKeyId: uploadVendorData.signatureData.accessId,
        signature: uploadVendorData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadVendorData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const handleVendorExceed = files => {
      uploadVendor.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadVendor.value.handleStart(file);
      uploadVendor.value.submit();
    };
    const handleVendorOnSuccess = async (response, file, files) => {
      debugger;
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadVendorData.data.name,
          uploadedName: uploadVendorData.data.key
        };
        importVendorRequest(uploadedRO);
      }
    };
    const importVendorRequest = async uploadedRO => {
      proxy.$api.vendorRequest.importVendor(uploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202156") {
            uploadVendorPopoverVisible.value = false;
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    return {
      permission,
      windowRef,
      xTable,
      searchInput,
      vendorData,
      VendorTypeData,
      addSupplier,
      editSupplier,
      addVendorInfo,
      editVendorInfo,
      imageUrl,
      editImageUrl,
      paginationData,
      popoverVisible,
      userLoading,
      paginationSizeChange,
      paginationCurrentChange,
      beforeAvatarUpload,
      beforeAvatarUploadEdit,
      supplierCancel,
      editCancel,
      searchClick,
      addSupplierApi,
      editSupplierApi,
      editVendor,
      deleteVendor,
      getVendorName,
      userTableScroll,
      openUploadVendorPopover,
      uploadVendor,
      uploadVendorPopoverVisible,
      uploadVendorData,
      handleVendorExceed,
      handleVendorBeforeUpload,
      handleVendorOnSuccess
    };
  }
};