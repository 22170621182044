// store.js

import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      headerTitle: null,
    };
  },
  mutations: {
    setHeaderTitle(state, value) {
      state.headerTitle = value;
    },
    clearHeaderTitle(state) {
      state.headerTitle = null;
    },
  },
  actions: {
    setHeaderTitle({ commit }, value) {
      commit("setHeaderTitle", value);
    },
    clearHeaderTitle({ commit }) {
      commit("clearHeaderTitle");
    },
  },
  getters: {
    headerTitle(state) {
      return state.headerTitle;
    },
  },
});

export default store;
