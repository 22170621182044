import "core-js/modules/es.array.push.js";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { Plus } from '@element-plus/icons-vue';
import util from "@/util/util";
import { useRoute, useRouter } from "vue-router";
import { ElLoading } from 'element-plus';

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: 'ReportRepair',
  components: {
    Plus,
    ElLoading
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRoute();
    const $r = useRouter();
    const storeCode = router.params.storeCode;
    const storeLocationId = router.params.storeLocationId;
    const storeName = router.params.storeName;
    const storeLocationName = router.params.storeLocationName;
    const nameInput = ref('');
    const mobileInput = ref('');
    const flag = ref('false');
    const dialogImageUrl = ref('');
    const remarkInput = ref('');
    const dialogVisible = ref(false);
    const uploadTable = ref(null);
    const fullscreenLoading = ref(false);
    const uploadTableData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      uploadedList: [],
      repairActivityObj: {}
    });
    //删除图片之前
    const handleBeforeRemove = (uploadFile, uploadFiles) => {
      let uploadDeleteRO;
      let deleteIndex = null;
      for (let i = 0; i < uploadTableData.uploadedList.length; i++) {
        if (uploadFile.uid === uploadTableData.uploadedList[i].uid) {
          console.log('第' + i + '个');
          deleteIndex = i;
          uploadDeleteRO = {
            uploadedName: uploadTableData.uploadedList[i].uploadedName
          };
          break;
        }
      }
      console.log(deleteIndex, uploadDeleteRO.uploadedName);
      fullscreenLoading.value = true;
      proxy.$api.repairRequest.deleteInstallationPhoto(uploadDeleteRO).then(function (response) {
        console.log(response);
        if (response.code === '200000') {
          ElNotification({
            title: "成功",
            message: response.result.promptMsg,
            type: "success",
            duration: 3000
          });
          uploadTableData.uploadedList.splice(deleteIndex, 1);
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
          return false;
        }
        fullscreenLoading.value = false;
      }).catch(function (error) {
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
        fullscreenLoading.value = false;
        return false;
      });
    };
    const handleRemove = (uploadFile, uploadFiles, index) => {
      console.log(uploadFile, uploadFiles, index);
    };
    const handlePictureCardPreview = uploadFile => {
      console.log('点击了');
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    const handleExceed = () => {
      ElNotification({
        title: "失败",
        message: "最多上传3张图片",
        type: "error",
        duration: 6000
      });
    };
    //文件上传成功的钩子
    const handleStoreOnSuccess = async (response, file) => {
      console.log('file' + file);
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadTableData.data.name,
          uploadedName: uploadTableData.data.key,
          uid: file.uid
        };
        uploadTableData.uploadedList.push(uploadedRO);
      }
    };

    // 获取文件签名等信息
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          reject(error);
        });
      });
    };

    //上传文件之前的钩子
    const handleStoreBeforeUpload = async file => {
      uploadTableData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadTableData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadTableData.signatureData.dir + fullName;
      uploadTableData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadTableData.signatureData.policy,
        OSSAccessKeyId: uploadTableData.signatureData.accessId,
        signature: uploadTableData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadTableData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    const selectActivityInfoByStoreLocationId = async repairActivityRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.selectActivityInfoByStoreLocationId(repairActivityRO, true).then(res => {
          if (res.code === "200000") {
            resolve(res.result.data);
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 6000
            });
            resolve(null);
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //提交
    const submitApi = () => {
      //判空
      let tips = '';
      if (nameInput.value === '') {
        tips = '请输入报修人！';
      } else if (mobileInput.value === '') {
        tips = '请输入联系方式！';
      } else if (remarkInput.value === '') {
        tips = '请输入需求描述！';
      } else if (uploadTableData.uploadedList.length === 0) {
        tips = '请上传报修故障照片!';
      }
      if (tips !== '') {
        ElNotification({
          title: "失败",
          message: tips,
          type: "error",
          duration: 3000
        });
        return;
      }
      fullscreenLoading.value = true;
      let activityId = uploadTableData.repairActivityObj ? uploadTableData.repairActivityObj.activityId : null;
      let repairAddRO = {
        activityId: activityId,
        repairUrgent: flag.value ? 1 : 0,
        reporterDescription: remarkInput.value,
        reporterName: nameInput.value,
        reporterMobile: mobileInput.value,
        storeCode: storeCode,
        storeLocationId: parseInt(storeLocationId),
        uploaded: uploadTableData.uploadedList
      };
      proxy.$api.repairRequest.addRepair(repairAddRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202280") {
            ElNotification({
              title: "成功",
              message: res.result.promptMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
          fullscreenLoading.value = false;
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
          fullscreenLoading.value = false;
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        fullscreenLoading.value = false;
      });
    };
    onMounted(async () => {
      let repairActivityRO = {
        storeLocationId: router.params.storeLocationId
      };
      let act = await selectActivityInfoByStoreLocationId(repairActivityRO);
      console.log(act);
      if (act == null) {
        ElNotification({
          title: "失败",
          message: '此点位无活动!',
          type: "error",
          duration: 3000
        });
        $r.go(-1);
      }
      uploadTableData.repairActivityObj = act;
    });
    return {
      nameInput,
      mobileInput,
      remarkInput,
      flag,
      dialogImageUrl,
      dialogVisible,
      uploadTableData,
      uploadTable,
      storeName,
      storeLocationName,
      fullscreenLoading,
      handleRemove,
      handlePictureCardPreview,
      handleStoreBeforeUpload,
      handleStoreOnSuccess,
      submitApi,
      handleExceed,
      handleBeforeRemove
    };
  }
};