import "core-js/modules/es.array.push.js";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { Plus, EditPen } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import util from "@/util/util";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "RepairCheck",
  components: {
    Plus,
    EditPen
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const value = ref("");
    const tab = ref(1);
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const drawBoxShow = ref(false);
    const textarea1 = ref("");
    const uploadTable = ref(null);
    const installationStatusData = reactive([]);
    const ysNameInput = ref("");
    const ysPhoneInput = ref("");
    const remarkInput = ref("");
    const drawBase64 = ref("");
    const uploadTableData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      uploadedList: [],
      storeInfo: {},
      urgentFlag: false,
      urgentText: null
    });
    const uploadResultPhotoData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      uploaded: []
    });
    //获取报修信息
    const selectRepairById = repairId => {
      let repairIdRO = {
        repairId: repairId
      };
      proxy.$api.repairRequest.selectRepairById(repairIdRO).then(function (response) {
        console.log(response);
        if (response.code === "200000") {
          uploadTableData.storeInfo = response.result.data;
          uploadTableData.uploadedList = response.result.data.fixPhoto;
          ElNotification({
            title: "成功",
            message: response.result.promptMsg,
            type: "success",
            duration: 1000
          });
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(function (error) {
        console.log(error);
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    //预览图片
    const handlePictureCardPreview = uploadFile => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    //返回
    const goBack = () => {
      router.go(-1);
    };
    //开关变化触发的函数
    const switchChang = a => {
      if (!a) {
        uploadTableData.urgentText = null;
      }
    };
    const signaturePad = ref();
    //画布撤销
    const undo = () => {
      signaturePad.value.clearSignature();
    };
    //画布完成
    const save = () => {
      const {
        isEmpty,
        data
      } = signaturePad.value.saveSignature();
      if (checkOrientation() == "landscape") {
        drawBase64.value = data;
        drawBoxShow.value = false;
      } else {
        rotateBase64(data).then(img => {
          drawBase64.value = img;
          drawBoxShow.value = false;
        });
      }
    };
    //旋转90度
    const rotateBase64 = data => {
      //传入需要旋转的base64图片
      return new Promise(resolve => {
        const imgView = new Image();
        imgView.src = data;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const cutCoor = {
          sx: 0,
          sy: 0,
          ex: 0,
          ey: 0
        }; // 裁剪坐标
        imgView.onload = () => {
          const imgW = imgView.width;
          const imgH = imgView.height;
          const size = imgH;
          canvas.width = size * 2;
          canvas.height = size * 2;
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          context.translate(size, size);
          context.rotate(Math.PI / 2 * 3);
          context.drawImage(imgView, 0, 0);
          const imgData = context.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
          canvas.width = imgH;
          canvas.height = imgW;
          context.putImageData(imgData, 0, 0);
          resolve(canvas.toDataURL("image/png"));
        };
      });
    };
    const goTab2 = () => {
      let nextSwitch = true;
      if (uploadTableData.urgentFlag && uploadTableData.urgentText == null) {
        nextSwitch = false;
        ElNotification({
          title: "错误",
          message: "请输入打开的缺陷描述！",
          type: "error",
          duration: 3000
        });
        return;
      }
      if (nextSwitch) {
        tab.value = 2;
      }
    };
    const goTab1 = () => {
      tab.value = 1;
    };
    //验收确认提交
    const submitAcceptResult = () => {
      // 判空
      if (ysNameInput.value === "") {
        ElNotification({
          title: "错误",
          message: "请输入验收人！",
          type: "error",
          duration: 3000
        });
        return;
      } else if (ysPhoneInput.value === "") {
        ElNotification({
          title: "错误",
          message: "请输入联系方式！",
          type: "error",
          duration: 3000
        });
        return;
      } else if (drawBase64.value === "") {
        ElNotification({
          title: "错误",
          message: "请输入签名！",
          type: "error",
          duration: 3000
        });
        return;
      }

      //参数
      let submitAcceptRO = {
        defect: uploadTableData.urgentText,
        remark: remarkInput.value,
        repairId: uploadTableData.storeInfo.repairId,
        reviewerMobile: ysPhoneInput.value,
        reviewerName: ysNameInput.value,
        reviewerSign: drawBase64.value,
        reviewPhotos: uploadResultPhotoData.uploaded
      };
      console.log(submitAcceptRO);
      // return false
      proxy.$api.repairRequest.submitAcceptInfo(submitAcceptRO).then(function (response) {
        console.log(response);
        if (response.code == "200000") {
          if (response.result.dataCode === "202284") {
            ElNotification({
              title: "成功",
              message: response.result.promptMsg,
              type: "success",
              duration: 3000
            });
            router.replace({
              path: "/check-finish/"
            });
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(function (error) {
        console.log(error);
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    onMounted(async () => {
      selectRepairById(route.query.id);
    });

    // 上传图片数量限制
    const maxNum = 3;
    //获取文件签名等信息
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          reject(error);
        });
      });
    };
    //上传文件之前
    const handleBefore = async file => {
      uploadResultPhotoData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadResultPhotoData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadResultPhotoData.signatureData.dir + fullName;
      uploadResultPhotoData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadResultPhotoData.signatureData.policy,
        OSSAccessKeyId: uploadResultPhotoData.signatureData.accessId,
        signature: uploadResultPhotoData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadResultPhotoData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    // 文件上传成功
    const handleStoreOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadResultPhotoData.data.name,
          uploadedName: uploadResultPhotoData.data.key,
          uid: file.uid
        };
        file.uploadedRO = {};
        file.uploadedRO = uploadedRO;
        uploadResultPhotoData.uploaded.push(uploadedRO);
      }
    };
    //超出文件限制数量
    const handleExceed = () => {
      ElNotification({
        title: "失败",
        message: "最多上传" + maxNum + "张图片",
        type: "error",
        duration: 3000
      });
    };
    const checkOrientation = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        //竖屏
        return "portrait";
      } else if (window.matchMedia("(orientation: landscape)").matches) {
        return "landscape";
      }
    };
    return {
      value,
      dialogImageUrl,
      dialogVisible,
      textarea1,
      uploadTable,
      uploadTableData,
      tab,
      ysNameInput,
      ysPhoneInput,
      remarkInput,
      drawBoxShow,
      signaturePad,
      drawBase64,
      installationStatusData,
      undo,
      save,
      goBack,
      handlePictureCardPreview,
      goBack,
      switchChang,
      goTab2,
      goTab1,
      submitAcceptResult,
      uploadResultPhotoData,
      maxNum,
      handleBefore,
      handleStoreOnSuccess,
      handleExceed
    };
  }
};