import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, inject, ref, reactive, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import permission from "@/util/permission";
import cookies from "js-cookie";
import util from "@/util/util";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { UserOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    UserOutlined
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userInfoData = util.getUserInfo();
    const dialogVisible = ref(false);
    const activeName = ref("1");
    const globalUser = inject("globalUser");
    const stompClient = ref(null);
    const buttonShow = ref(true);
    const route = useRoute();
    const currentPath = ref(route.path);
    if (userInfoData != null) {
      globalUser.avatar = userInfoData.avatar;
      globalUser.email = userInfoData.email;
      globalUser.firstName = userInfoData.firstName;
      globalUser.id = userInfoData.id;
      globalUser.lastName = userInfoData.lastName;
      globalUser.managerId = userInfoData.managerId;
      globalUser.type = userInfoData.type;
    }
    const pathToMenus = [{
      url: "activity-browser",
      key: "1"
    }, {
      url: "list-interaction",
      key: "2"
    }, {
      url: "report-interaction",
      key: "2"
    }, {
      url: "create-interaction",
      key: "2"
    }, {
      url: "test-interaction",
      key: "2"
    }, {
      url: "repair-manage",
      key: "4"
    }, {
      url: "store-manage",
      key: "5"
    }, {
      url: "user-manage",
      key: "6"
    }];
    const state = reactive({
      collapsed: false,
      selectedKeys: ["1"],
      openKeys: ["sub1"],
      preOpenKeys: ["sub1"]
    });
    let menuUrl = pathToMenus.find(menu => currentPath.value.includes(menu.url)) || null;
    if (menuUrl != null) {
      state.selectedKeys[0] = menuUrl.key;
    }
    const barCommand = key => {
      console.log(111111, key.key);
      if (key.key == "logout") {
        sessionStorage.removeItem("token");
        cookies.remove("token");
        router.replace("/");
      }
      if (key.key == "modify") {
        router.push("/user-modify");
      }
    };
    const goBack = () => {
      router.go(-1);
    };
    const handleClick = (tab, event) => {
      console.log(tab, event);
    };
    onMounted(() => {
      var menuButton = document.getElementById("menu-button");
      var sidebar = document.getElementById("left-sidebar");
      var menubar = document.getElementById("left-menubar");
      menuButton.addEventListener("click", function () {
        sidebar.classList.toggle("sidebar-hidden");
      });
      sidebar.addEventListener("click", function () {
        sidebar.classList.toggle("sidebar-hidden");
      });
      menubar.addEventListener("click", function (event) {
        event.stopPropagation(); // 阻止事件冒泡
      });

      connect();
    });
    const menuSelect = (index, indexPath) => {
      switch (index.key) {
        case "1":
          router.push("/activity-browser");
          break;
        case "2":
          router.push("/list-interaction");
          break;
        case "3":
          // router.push("/vendor-manage");
          break;
        case "4":
          router.push("/repair-manage");
          break;
        case "5":
          router.push("/store-manage");
          break;
        case "6":
          router.push("/user-manage");
          break;
        case "7":
          // router.push("/repair-manage");
          break;
      }
      console.log(index, indexPath);
    };
    const connect = () => {
      let tokenJson = util.getToken();
      const socket = new SockJS("/api/ws?token=" + tokenJson.access_token);
      stompClient.value = Stomp.over(socket);
      var headers = {
        Authorization: "Bearer " + tokenJson.access_token
      };
      stompClient.value.connect(headers, frame => {
        console.log("Connected: " + frame);
        stompClient.value.subscribe("/user/queue/messages", message => {
          console.log(message.body);
          // 这里处理接收到的消息
          // console.log(JSON.parse(message.body).content);
        });
      });
    };

    // const sendMessage = () => {
    //   stompClient.value.send("/api/app/message", {}, JSON.stringify(message));
    // };
    // 监听路由变化
    watch(route, newRoute => {
      if (newRoute.fullPath === "/list-interaction" || newRoute.fullPath === "/activity-browser") {
        buttonShow.value = false;
      } else {
        buttonShow.value = true;
      }
    }, {
      immediate: true
    });
    return {
      menuSelect,
      permission,
      barCommand,
      store,
      goBack,
      globalUser,
      dialogVisible,
      handleClick,
      activeName,
      //2.0
      state,
      buttonShow
    };
  }
});