import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import "vue-cropper/dist/index.css";
import { VueCropper } from "vue-cropper";
export default {
  emits: ["imgUrlbase64"],
  name: "Tailoring",
  components: {
    VueCropper
  },
  data() {
    return {
      model: false,
      modelSrc: "",
      crap: false,
      previews: {},
      option: {
        img: "",
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 117,
        autoCropHeight: 117,
        centerBox: true,
        high: true,
        max: 99999
      },
      show: true,
      fixed: true,
      fixedNumber: [1, 1]
    };
  },
  methods: {
    // 实时预览函数
    realTime(data) {
      console.log(data);
      data.div.width = '117px';
      data.div.height = '117px';
      this.previews = data;
    },
    down(type) {
      // event.preventDefault()
      var aLink = document.createElement("a");
      aLink.download = "demo";
      // 输出
      this.$refs.cropper.getCropData(data => {
        this.downImg = data;
        // this.option.img = data;
        // console.log(data)
        this.$emit("imgUrlbase64", data);
      });
    },
    uploadImg(e, num) {
      //上传图片
      // this.option.img
      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      var reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          this.option.img = data;
        } else if (num === 2) {
          this.example2.img = data;
        }
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    }
  }
};