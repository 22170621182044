import { createRouter, createWebHistory } from "vue-router";
import api from "./api";
import cookies from "js-cookie";
import store from "./store";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import ActivityBrowser from "@/views/ActivityBrowser.vue";
import ActivityCreate from "@/views/ActivityCreate.vue";
import ActivityDetail from "@/views/ActivityDetail.vue";
import StoreManage from "@/views/StoreManage.vue";
import RepairManage from "@/views/RepairManage.vue";
import UserManage from "@/views/UserManage.vue";
import VendorManage from "@/views/VendorManage.vue";
import StoreLocation from "@/views/StoreLocation.vue";
import ConveyPlan from "@/views/ConveyPlan.vue";
import InstallConvey from "@/views/InstallConvey.vue";
import ReportRepair from "@/views/ReportRepair.vue";
import RepairMessage from "@/views/RepairMessage.vue";
import RepairInfo from "@/views/RepairInfo.vue";
import RepairCheck from "@/views/RepairCheck.vue";
import InstallInfo from "@/views/InstallInfo.vue";
import InstallCheck from "@/views/InstallCheck.vue";
import EmailVerification from "@/views/EmailVerification.vue";
import UserModify from "@/views/UserModify.vue";
import CheckFinish from "@/views/CheckFinish.vue";
import WxCheck from "@/views/WxCheck.vue";
import Test from "@/views/Test.vue";
import CreateInteraction from "@/views/CreateInteraction.vue";
import TestInteraction from "@/views/TestInteraction.vue";
import ListInteraction from "@/views/ListInteraction.vue";
import ReportInteraction from "@/views/ReportInteraction.vue";
import StoreLocation1 from "@/views/StoreLocation1.vue";

const autoLogin = (next) => {
  var remember = false;
  let token = sessionStorage.getItem("token");
  if (token == null) {
    remember = true;
    token = cookies.get("token");
  }
  if (token == null) {
    next();
  }
  var tokenJson = JSON.parse(token);
  api.userRequest
    .refreshToken(tokenJson.refresh_token)
    .then((data) => {
      if (remember === true) {
        sessionStorage.removeItem("token");
        cookies.set("token", JSON.stringify(data), {
          expires: 30,
        });
      } else {
        cookies.remove("token");
        sessionStorage.setItem("token", JSON.stringify(data));
      }
      next("/activity-browser");
    })
    .catch((error) => {
      next();
    });
};

const routes = [
  {
    path: "/login",
    component: Login,
    beforeEnter: (to, from, next) => {
      autoLogin(next);
    },
  },
  {
    path: "/",
    component: Login,
    beforeEnter: (to, from, next) => {
      autoLogin(next);
    },
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/activity-browser",
        component: ActivityBrowser,
      },
      {
        path: "/activity-create/:activityId?",
        component: ActivityCreate,
      },
      {
        path: "/activity-detail/:activityId",
        component: ActivityDetail,
      },
      {
        path: "/store-manage/:activityId?/:storeTypeId?",
        component: StoreManage,
      },
      {
        path: "/repair-manage",
        component: RepairManage,
      },
      {
        path: "/user-manage",
        component: UserManage,
      },
      {
        path: "/user-modify",
        component: UserModify,
      },
      {
        path: "/vendor-manage",
        component: VendorManage,
      },
      {
        name: "store-location",
        path: "/store-location/:storeCode",
        component: StoreLocation,
      },
      {
        name: "store-location1",
        path: "/store-location1/:storeCode",
        component: StoreLocation1,
      },
      {
        path: "/convey-plan/:activityId?/:vendorId?",
        component: ConveyPlan,
      },
      {
        path: "/install-convey/:activityId?/:vendorId?",
        component: InstallConvey,
      },
      {
        path: "/create-interaction/:itemId?",
        component: CreateInteraction,
      },
      {
        path: "/test-interaction/:itemId",
        component: TestInteraction,
      },
      {
        path: "/list-interaction",
        component: ListInteraction,
      },
      {
        path: "/report-interaction/:itemId/:reportItemId?",
        component: ReportInteraction,
      },
    ],
  },
  {
    //报修填写
    path: "/report-repair/:storeLocationId/:storeCode/:storeName/:storeLocationName",
    name: "report-repair",
    component: ReportRepair,
  },
  {
    //报修信息查看
    path: "/repair-message",
    component: RepairMessage,
  },
  {
    //维修信息填写
    path: "/repair-info",
    component: RepairInfo,
  },
  {
    //维修后店员确认
    path: "/repair-check",
    component: RepairCheck,
  },
  {
    //安装信息填写
    path: "/install-info/:activityId/:vendorId",
    component: InstallInfo,
  },
  {
    //安装信息确认
    path: "/install-check/:activityId/:vendorId/:storeCode",
    component: InstallCheck,
  },
  {
    //邮箱验证
    path: "/email-verification/:username/:code",
    component: EmailVerification,
  },
  {
    path: "/check-finish",
    component: CheckFinish,
  },
  {
    path: "/wx-check",
    component: WxCheck,
  },
  {
    path: "/datacenter",
    component: Test,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("setHeaderTitle", "");
  next();
});
export default router;
