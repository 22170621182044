import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { SuccessFilled } from "@element-plus/icons-vue";
import util from "@/util/util";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "InstallInfo",
  components: {
    SuccessFilled
  },
  setup() {
    return {};
  }
};